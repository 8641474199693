import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HTTPService } from './utils';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmWithTwoBtnsComponent } from '../views/popups/confirm-with-two-btns/confirm-with-two-btns.component';
import { SessionService } from './session.service';
import { PopupHandlerService } from './popup-handler.service';

@Injectable()
export class TerminateSessionService extends HTTPService {

  constructor(
      protected http: HttpClient,
      private modalService: NgbModal,
      private sessionService: SessionService,
      private popupHandlerService: PopupHandlerService,

    ) {
    super(http);
  }

  terminateSessionPopup(sessionId: number,refreshState?) {
    let config: NgbModalOptions = {
      backdrop: 'static',
      size: 'sm',
      centered: true
    }
    const modalRef = this.modalService.open(ConfirmWithTwoBtnsComponent, config);
    modalRef.componentInstance.title = 'Information';
    modalRef.componentInstance.message = 'Etes-vous sur de mettre fin à cette session?';
    modalRef.componentInstance.okText = 'valider';
    modalRef.componentInstance.okAction = this.validateBeforeTerminate.bind(this,sessionId,modalRef,refreshState);
    modalRef.componentInstance.cancelAction = () => modalRef.close('close');
  }

  validateBeforeTerminate(sessionId: number , modalRef,refreshState) {
    modalRef.close();
    this.sessionService.terminateSession(sessionId).subscribe(() => {
      this.sessionService.getSessions();
      if(refreshState)
        refreshState();
    }, error => {
      this.popupHandlerService.openOneBtnPopup('Information', error.reason, 'Ok', true);
    });
  }

}
