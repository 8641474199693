import { Component, OnInit, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { SortEvent, SortableHeaderDirective } from 'src/app/directives/sort-header.directive';
import { compare, Session, SessionStatus } from '../../../models/session';
import { SessionService } from 'src/app/services/session.service';
import { User } from 'src/app/models/user';
import { ApiService } from 'src/app/services/api.service';
import { CreateSessionPopupHandlerService } from 'src/app/services/create-session-popup-handler.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedDataService } from 'src/app/services/shared-data.service';


@Component({
  selector: 'app-sessions-list',
  templateUrl: './sessions-list.component.html',
  styleUrls: ['./sessions-list.component.scss']
})
export class SessionsListComponent implements OnInit {
  filterByPending: SessionStatus[] = [SessionStatus.PENDING];
  filterByCanceled: SessionStatus[] = [SessionStatus.CANCELED];
  filterByRefused: SessionStatus[] = [SessionStatus.REFUSED];
  filterByScheduled: SessionStatus[] = [
    SessionStatus.CONFIRMED,
    SessionStatus.VALIDATED
  ];
  browserLocale = 'fr';
  dateTimeformat = 'dd/MM/yyyy HH:mm';
  filterByStatus: SessionStatus[] = this.filterByPending;
  isClient = false;
  sessions: Session[] = [];
  currentSession: Session;
  currentUser: User;
  originalSessions: Session[] = [];
  userSortingChoices: SortEvent[];
  @Output() selected: EventEmitter<Session> = new EventEmitter();

  constructor(private sessionService: SessionService,
    private api: ApiService,
    private createSessionPopupHandlerService: CreateSessionPopupHandlerService,
    private _translate: TranslateService,
    private sharedDataservice: SharedDataService) { }

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  ngOnInit() {
    this.browserLocale = this._translate.getBrowserLang() === 'en' ? 'en' : 'fr';
    this.dateTimeformat = this.browserLocale === 'en' ? 'dd/MM/yyyy h:mm a' : 'dd/MM/yyyy HH:mm';
    this.api.authenticatedUser.subscribe((user) => {
      if (user)
        this.currentUser = user
    });
    this.isClient = this.currentUser.type == "C" ? true : false;
    this.sessionService.sessions.subscribe(sessions => {
      this.sessions = sessions;
      this.sessions = [...this.sessions].sort((a, b) => {
        return compare(a, b);
      });

      this.originalSessions = sessions;

      this.sharedDataservice.userSortingChoices.subscribe(choices => {
        this.userSortingChoices = choices;
        this.sessions = this.sharedDataservice.sortUserchoice(0, this.headers, this.originalSessions)
      })
    });
    this.sessionService.getSessions();
  }

  get filteredSessions() {
    return this.sessions.filter(
      session => this.filterByStatus.indexOf(session.etat) !== -1
    );
  }

  getNumberOfSession(filterByStatus) {
    return this.sessions.filter(
      session => filterByStatus.indexOf(session.etat) !== -1
    ).length;
  }

  onSelectSession(session: Session) {
    this.currentSession = session;
    this.selected.emit(session);
  }

  clearCurrentSession() {
    this.currentSession = null;
    this.selected.emit(null);
  }

  onSort({ column, direction }: SortEvent) {
    this.userSortingChoices[0] = { column, direction };
    this.sharedDataservice.changeUserSortingChoices(this.userSortingChoices)
  }

  getStatusIcon(session: Session) {
    const statusIcon = {};
    statusIcon[SessionStatus.PENDING] = 'assets/fleche1.gif';
    statusIcon[SessionStatus.CANCELED] = 'assets/cross.png';
    statusIcon[SessionStatus.REFUSED] = 'assets/stop.png';
    statusIcon[SessionStatus.CONFIRMED] = 'assets/tick.png';
    statusIcon[SessionStatus.VALIDATED] = 'assets/tick.png';
    return statusIcon[session.etat];
  }

  openCreateNewSession() {
    this.createSessionPopupHandlerService.openCreateNewSession(this.isClient);
  }
}
