import { Type, Transform } from 'class-transformer';

export const compare = (v1, v2) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

export enum SessionStatus {
    CANCELED = 'annulee',
    PENDING = 'atraiter',
    CONFIRMED = 'confirmee',
    VALIDATED = 'validee',
    REFUSED = 'refusee',
    FINISHED = 'terminee',
    ARCHIVED = 'archivee'
}

export class Session {
    id: number;
    id_client: number;
    id_velo: number;

    intitule: string;

    @Transform(value => (value === 'Y'), { toClassOnly: true })
    acces_client: boolean;

    capture: string;
    channel: string;

    client_display_name: string;

    description: string;

    duree: string; // '00:00:00'

    // beginDate: '21/03/19'
    // beginTime: '13h00'
    // endDate: '21/03/19'
    // endTime: '14h00'

    @Transform(value => (value === 'Y'), { toClassOnly: true })
    envoi_courrier: boolean;

    @Transform(value => (value === 'Y'), { toClassOnly: true })
    envoi_mail: boolean;

    etat: SessionStatus;

    @Type(() => Date)
    date_demande: Date;

    @Type(() => Date)
    debut: Date;

    @Type(() => Date)
    fin: Date;

    participants: any[];

    @Transform(value => (value === 'Y'), { toClassOnly: true })
    publique: boolean;

    sessionStart: number; // timestamp '1551174468'
    theme: string; // 'Présentation'
    velotypiste: string;
}
