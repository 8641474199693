import { OnInit, Component } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AddUserComponent } from '../popups/add-user/add-user.component';
import { ConfirmWithTwoBtnsComponent } from '../popups/confirm-with-two-btns/confirm-with-two-btns.component';
import { PopupHandlerService } from 'src/app/services/popup-handler.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  users: User[] = [];
  currentUser: User;
  search: string;
  placeholder = "Rechercher par nom ou prénom";
  userType;
  modalRef: NgbModalRef;

  adminOptions = [
    'gérer les clients',
    'gèrer le personnel',
    'gérer les sessions'
  ];
  staffType = [
    'Transcripteur',
    'Admin',
    'Transcripteur/Admin',
    'Correcteur',
    'Transcripteur/Correcteur',
    'Correcteur/Admin',
    'Transcripteur/Correcteur/Admin'
  ];
  //for toast
  isAdded = false;
  isUpdated = false;
  isDeleted = false;
  firstName = "";
  lastName = "";

  constructor(
    private api: ApiService,
    private modalService: NgbModal,
    private router: Router,
    private popupHandlerService: PopupHandlerService
  ) { }

  ngOnInit() {
    this.clearConfirmationMessagesData();
    this.api.users.subscribe(users => {
      this.users = users;
      if (this.currentUser) {
        this.users.map(u => {
          if (u.id == this.currentUser.id) {
            this.setUser(u);
          }
        })
      }
    });
    if (this.router.url == '/users') {
      this.userType = 'P';

    } else if (this.router.url == '/clients') {
      this.userType = 'C';
    }
  }

  isOptionAvailable(adminOption) {
    switch (adminOption) {
      case this.adminOptions[0]:
        return this.currentUser.gestion_clients === 'Y';
      case this.adminOptions[1]:
        return this.currentUser.gestion_personnel === 'Y';
      case this.adminOptions[2]:
        return this.currentUser.gestion_demandes === 'Y';
    }
  }
  getStaffType() {
    let isAdmin: boolean, isVelotypiste: boolean, isCorrecteur: boolean;
    if (this.currentUser.fonctions.indexOf('velotypiste') != -1) {
      isVelotypiste = true;
    }
    if (this.currentUser.fonctions.indexOf('admin') != -1) {
      isAdmin = true;
    }
    if (this.currentUser.fonctions.indexOf('correcteur') != -1) {
      isCorrecteur = true;
    }
    if (!isAdmin && isVelotypiste && !isCorrecteur) return this.staffType[0];
    if (isAdmin && !isVelotypiste && !isCorrecteur) return this.staffType[1];
    if (isAdmin && isVelotypiste && !isCorrecteur) return this.staffType[2];
    if (!isAdmin && !isVelotypiste && isCorrecteur) return this.staffType[3];
    if (!isAdmin && isVelotypiste && isCorrecteur) return this.staffType[4];
    if (isAdmin && !isVelotypiste && isCorrecteur) return this.staffType[5];
    if (isAdmin && isVelotypiste && isCorrecteur) return this.staffType[6];
  }
  get filteredUsers() {
    return !!this.search
      ? this.users.filter(
        (u: User) => u.displayName.toLowerCase().indexOf(this.search) !== -1
      )
      : this.users;
  }

  addNewUser() {
    let config: NgbModalOptions = {
      centered: true,
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    }
    this.modalRef = this.modalService.open(AddUserComponent, config);
    this.modalRef.componentInstance.userType = this.userType;
    this.modalRef.result.then((result) => {
      this.users = result.users;
      if (result.isAdded) {
        this.isAdded = true;
        this.firstName = result.firstName;
        this.lastName = result.lastName;
        setTimeout(() => {
          this.isAdded = false;
          this.clearConfirmationMessagesData(); 
        }, 6000);
      }
    })
  }

  setUser(user: User) {
    this.currentUser = user;
  }

  clearConfirmationMessagesData(){
    this.firstName = "";
    this.lastName = "";
  }

  deleteUserPopup() {
    let config: NgbModalOptions = {
      centered: true,
      backdrop: 'static',
      keyboard: false,
      size: 'sm'
    }
    this.modalRef = this.modalService.open(ConfirmWithTwoBtnsComponent, config);
    this.modalRef.componentInstance.title = 'Information';
    this.modalRef.componentInstance.message = 'Voulez vraiment supprimer cette personne?';
    this.modalRef.componentInstance.okText = 'Accepter';
    this.modalRef.componentInstance.okAction = this.deleteUser.bind(this);
    this.modalRef.result.then((result) => {

      if (result.isDeleted) {
        this.isDeleted = true; 
        this.firstName = this.currentUser.prenom; 
        this.lastName = this.currentUser.nom; 
        this.setUser(null); 
        setTimeout(() => {
          this.isDeleted = false;
          this.clearConfirmationMessagesData(); 
        }, 6000);
      }
    })
    this.modalRef.componentInstance.cancelAction = () => this.modalRef.close('close');
  }

  deleteUser() {
    let currentUserId = this.currentUser.id;
    if (currentUserId) {
      this.api.deleteUser(currentUserId).subscribe(() => {
        if (this.userType == 'P') {
          this.api.getPersonnels().subscribe();
        } else if (this.userType == 'C') {
          this.api.getClients().subscribe();
        }
       this.modalRef.close({ isDeleted: true });
      }, error => {
       this.modalRef.close({ isDeleted: false });
        this.popupHandlerService.openOneBtnPopup("Information", error.reason, "Ok", true);
      });
    }
  }

  modifyUserPopup() {
    let config: NgbModalOptions = {
      centered: true,
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    }
    this.modalRef = this.modalService.open(AddUserComponent, config);
    this.modalRef.componentInstance.isModify = true;
    this.modalRef.componentInstance.currentUser = this.currentUser;
    this.modalRef.componentInstance.userType = this.userType;
    this.modalRef.result.then((result) => {
      this.users = result.users;
      if (result.isAdded) {
        this.isUpdated = true;
        this.firstName = result.firstName;
        this.lastName = result.lastName;
        setTimeout(() => {
          this.isUpdated = false;
          this.clearConfirmationMessagesData(); 
        }, 6000);
      }
    })
  }

}
