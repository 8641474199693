import { OnInit, Component, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PopupHandlerService } from 'src/app/services/popup-handler.service';
import { ConfirmWithTwoBtnsComponent } from '../../popups/confirm-with-two-btns/confirm-with-two-btns.component';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  email: string;
  errorMessage: string;
  forgotPwdQuestion: string;
  forgotPwdText: string;
  validEmail: boolean = false
  modalRef: NgbModalRef;
  checkConditionMsg: string;

  @ViewChild("usernameInput") private usernameInputElt: ElementRef;

  constructor(
    private api: ApiService,
    private router: Router,
    private modalService: NgbModal,
    private popupHandlerService: PopupHandlerService
  ) { }

  ngOnInit() {
    this.usernameInputElt.nativeElement.focus();
    this.username = "";
    this.password = "";
    this.errorMessage = "";
    this.email = "";

    this.api.getCheckConditionMsg().subscribe((data: string) => {
      this.checkConditionMsg = data;
    }, err => console.log(err))
  }



  authenticate() {
    this.api.authenticate(this.username, this.password).subscribe(
      currentUser => {
        if (currentUser.type === 'C' && currentUser.temporaire == true) {
          let config: NgbModalOptions = {
            backdrop: 'static',
            size: 'lg',
            centered: true
          }
          this.modalRef = this.modalService.open(ConfirmWithTwoBtnsComponent, config);
          this.modalRef.componentInstance.title = 'Information';
          this.modalRef.componentInstance.message = this.checkConditionMsg.replace(/\n/g, '<br>');

          this.modalRef.componentInstance.okText = 'valider';
          this.modalRef.componentInstance.okAction = () => {
            this.api.checkCondition('accept').subscribe();
            this.modalRef.close('close');
            this.router.navigate(['/calendar'])
          };
          this.modalRef.componentInstance.cancelAction = () => {
            this.api.checkCondition('refuser').subscribe();
            this.modalRef.close('close');
            this.api.logout();
          }
        }
        else {
          let homepage = "/calendar";
          if (currentUser.type == "P" && currentUser.fonctions.indexOf['admin'] != -1 && currentUser.gestion_demandes === 'Y') {
            homepage = "/sessions";
          }
          else if (currentUser.type == "P" && currentUser.fonctions.indexOf['admin'] != -1 && currentUser.gestion_clients === 'Y') {
            homepage = "/clients";
          }
          else if (currentUser.type == "P" && currentUser.fonctions.indexOf['admin'] != -1 && currentUser.gestion_personnel === 'Y') {
            homepage = "/users";
          }
          this.router.navigate([homepage]);
        }

      },
      error => {
        if (error.status == "BAD_AUTHENTICATION") {
          this.errorMessage = "Identifiant / Mot de passe incorrect"
        }
      });
  }

  openForgetPassword(content) {
    this.forgotPwdQuestion = "Vous avez oublié votre mot de passe ?"
    this.forgotPwdText = "Mot de passe oublié help";
    this.validEmail = false;
    this.modalService.open(content, { centered: true, ariaLabelledBy: 'modal-forget-password', backdrop: 'static' })
  }

  generateNewPassword() {
    this.api.generateNewPassword(this.email).subscribe(
      (data) => {
        if (data.code == 0) {
          this.modalService.dismissAll();
          this.popupHandlerService.openOneBtnPopup("Information", "Votre nouveau mot de passe vient de vous être envoyé", "Ok", false);
        }
      },
      error => {
        if (error != 0) {
          this.popupHandlerService.openOneBtnPopup("Information", "Désolé, aucun compte avec cette adresse email a été trouvé", "Ok", true);
        }
      }
    );
  }

  onChange(newValue) {
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.email = newValue;
    if (validEmailRegEx.test(newValue)) {
      this.validEmail = true;
    } else {
      this.validEmail = false;
    }
  }

  blurAndAuthenticate($event) {
    $event.target.blur();
    this.authenticate();
  }
}
