import { Component, OnInit } from '@angular/core';
import { Session } from '../../models/session';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.sass']
})
export class SessionsComponent implements OnInit {
  currentSession: Session;

  constructor(public translate: TranslateService) {}

  ngOnInit() {

  }

  setCurrentSession(session) {
    this.currentSession = session;
  }
}
