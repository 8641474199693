import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { ApiService } from './services/api.service';
import { map, flatMap, tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Injectable()
export class GetClientsResolver implements Resolve<Observable<any>> {
    constructor(
        private api: ApiService,
        private datePipe: DatePipe
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return forkJoin(
            this.api.getClients(),
            this.api.getPlanning(this.datePipe.transform(new Date(), 'yyyy/MM/dd'))
        ).pipe(
            map((datas: any[]) => {
                const [clients, planning] = datas;
                return { clients, planning };
            })
        );
    }
}

@Injectable()
export class GetPersonnelsResolver implements Resolve<Observable<any>> {
    constructor(
        private api: ApiService,
        private datePipe: DatePipe
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return forkJoin(
            this.api.getPersonnels(),
            this.api.getPlanning(this.datePipe.transform(new Date(), 'yyyy/MM/dd'))
        ).pipe(
            map((datas: any[]) => {
                const [clients, planning] = datas;
                return { clients, planning };
            })
        );
    }
}
