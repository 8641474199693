import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-settings-popup',
  templateUrl: './settings-popup.component.html',
  styleUrls: ['./settings-popup.component.scss']
})
export class SettingsPopupComponent implements OnInit {

  constructor(public modal: NgbActiveModal) { }
  title: string;
  okText: string;
  announcementMessage: string;
  okAction: any;
  placeholderAnnouncement = "announcement-placeholder";
  settingsForm: FormGroup;
  isDirty = false;
  ngOnInit() {
    this.createSettingsForm();
  }

  createSettingsForm() {
    this.settingsForm = new FormGroup({
      announcementMessage: new FormControl(this.announcementMessage)
    });
  }

  okBtnFunction() {
    if (!this.settingsForm.dirty && !this.isDirty) {
      this.close();
    }
    else {
      this.announcementMessage = this.settingsForm.value.announcementMessage;
      this.okAction();
    }
  }

  clearAnnouncementMessage() {
    this.settingsForm.controls['announcementMessage'].setValue("");
    this.isDirty = true;
  }

  close() {
    this.modal.close('close');
  }
}
