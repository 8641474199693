import { catchError, map } from "rxjs/internal/operators";
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { ApiService } from '../services/api.service';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(
    private api: ApiService, 
    private router: Router) { }
  /**
   * intercept all XHR request
   * @param request
   * @param next
   * @returns {Observable<A>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.body.code && event.body.code === 100) {
          this.api.clearData();
          this.router.navigate(['/login']);
        }

        return event;
      }),

      catchError((error, caught) => {
        //intercept the respons error and displace it to the console
        console.log('CustomHttpInterceptor', error);
        //handle your auth error or rethrow
        if (error.status === 401) {
          //navigate /delete cookies or whatever
          console.log('handled error ' + error.status);
          this.router.navigate(['']);
          // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
          return of(error.message);
        }
        throw error;
      }))
  }
}
