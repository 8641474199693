import { Component, OnInit } from '@angular/core';
import { TranscriptionService } from 'src/app/services/transcription.service';

@Component({
  selector: 'app-transcription-extracted-info',
  templateUrl: './transcription-extracted-info.component.html',
  styleUrls: ['./transcription-extracted-info.component.scss']
})
export class TranscriptionExtractedInfoComponent implements OnInit {

  allUsers = [];
  constructor(public transcriptionService: TranscriptionService) { }

  ngOnInit() {
    let velotypiste = this.transcriptionService.currentSession.velotypiste;

    let client = this.transcriptionService.currentSession.client;

    this.allUsers.push(velotypiste, client);

    let participants = this.transcriptionService.currentSession.participants;
    if (!!participants) {
      this.allUsers.push(...participants);
    }
  }

  isUserOnline(participant) {
    return this.transcriptionService.participants.filter(p => p.id == participant.id).length != 0;
  }

  showParticipant(participant) {
    return participant.prenom + " " + participant.nom;
  }

}