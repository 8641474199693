import { Component, OnInit, SimpleChanges, Input, Renderer2, ElementRef, ViewChild, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'src/app/services/message.service';
import { DictionnaryService } from 'src/app/services/dictionnary.service';
import { SocketService } from 'src/app/services/socket.service';
import { TranscriptionService } from 'src/app/services/transcription.service';

@Component({
  selector: 'app-transcription-textarea',
  templateUrl: './transcription-textarea.component.html',
  styleUrls: ['./transcription-textarea.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class TranscriptionTextareaComponent implements OnInit {

  @ViewChild('textarea') textarea: ElementRef;
  @ViewChild('fontColorPicker') fontColorPicker: ElementRef;
  @ViewChild('backgroundColorPicker') backgroundColorPicker: ElementRef;
  autoScrollEnabled = true;
  isFullScreenAvailable = false;

  fontFamily = ["Arial", "Comic Sans MS", "Courier New", "Lucida Console", "Lucida Sans Unicode", "MS Sans Serif", "MS Serif", "Tahoma", "Times New Roman", "Trebuchet MS", "Verdana"];
  fontSize = ["14px", "18px", "24px", "36px", "72px"];
  colors = [['black', 'white', 'blanchedalmond', 'rgb(255, 128, 0);', 'hsv 100 70 50'], ['red', 'yellow', 'green', 'blue', 'violet']];

  selectedFontFamily = "Verdana";
  selectedFontColor = "#FFFFFF";
  selectedFontSize = "24px";
  selectedBackgroundColor = "#000000";
  styles;

  oncontextmenu = function (event) { return false; };

  _currentWord = "";
  _currentStartIndex = 0;
  _suppressEvent = false;
  shortcuts = {};

  constructor(private renderer: Renderer2,
    private messageService: MessageService,
    private dictionaryService: DictionnaryService,
    private socketService: SocketService,
    public transcriptionService: TranscriptionService
  ) {
    this.updateDictionnary();
  }

  ngOnInit() {
    // depend on the user
    if (this.transcriptionService.isVelotypiste()) {
      this.enableEvent();
    }
    this.setStyle();
  }

  /* Tool Bar Editor Functions */

  updateDictionnary() {
    if (this.transcriptionService.isVelotypiste()) {
      this.dictionaryService.dictionary
        .subscribe((dico) => {
          this.shortcuts = this.dictionaryService.formatDictionary(dico);
        })
      this.dictionaryService.getAll();
    }
  }


  setFullScreen() {
    const elem: any = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
      document.addEventListener("fullscreenchange", this.onFullScreenChange.bind(this));
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
      document.addEventListener("mozfullscreenchange", this.onFullScreenChange.bind(this));
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
      document.addEventListener("webkitfullscreenchange", this.onFullScreenChange.bind(this));
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
      document.addEventListener("msfullscreenchange", this.onFullScreenChange.bind(this));
    }
  }

  addCss() {
    if (this.transcriptionService.hasVelotypisteAndClient) {
      if (this.transcriptionService.isVelotypiste()) {
        return 'online';
      }
      return 'online fill-panel-footer';
    }
    else {
      if (this.transcriptionService.isVelotypiste()) {
        return 'flash';
      }
      return 'flash fill-panel-footer';
    }
  }

  onFullScreenChange() {
    let doc: any = document;
    this.isFullScreenAvailable = (doc.fullScreen || doc.mozFullScreen || doc.webkitIsFullScreen) ? true : false;
  }

  onResize(event) {
    const windowHeight = event.target.innerHeight;
    if (windowHeight == screen.height) {
      this.isFullScreenAvailable = true;
    }
    else {
      this.isFullScreenAvailable = false;
    }
  }

  toggleScroll() {
    this.autoScrollEnabled = !this.autoScrollEnabled;
  }

  close() {
    window.close();
  }

  openFontColorPicker() {
    this.fontColorPicker.nativeElement.click();
  }

  openBackgroundColorPicker() {
    this.backgroundColorPicker.nativeElement.click();
  }

  setStyle() {
    this.styles = {
      "color": this.selectedFontColor,
      "font-family": this.selectedFontFamily,
      "font-size": this.selectedFontSize,
      "background-color": this.selectedBackgroundColor
    }
  }


  /* Text Area Events' Handlers */
  enableEvent() {
    this.renderer.listen(this.textarea.nativeElement, 'click', this.onClick.bind(this));
    this.renderer.listen(this.textarea.nativeElement, 'cut', this.onCut.bind(this));
    this.renderer.listen(this.textarea.nativeElement, 'paste', this.onPaste.bind(this));
    this.renderer.listen(this.textarea.nativeElement, 'keydown', this.onKeyDown.bind(this));

    //keyPress  letter number
    this.renderer.listen(this.textarea.nativeElement, 'keypress', this.onKeyPress.bind(this));
    // this.renderer.listen(this.textarea.nativeElement, 'select', this.onSelection.bind(this));
    // this.textarea.nativeElement.oncontextmenu= function(event){ return false; };
  }

  onchange(command) {
    const userName = this.transcriptionService.getCurrUserDisplayName();
    this.socketService.sendData(userName, command, true);
  }

  onClick(event) {
    this.sendCurrentWord();
  }

  onCut(event) {
    this.deleteChars(event, 0);
  }

  onPaste(event) {
    this.deleteChars(event, 0);
    if (this.onchange) this.onchange(this.messageService.buildUpdateActiveTextMessage(event.clipboardData.getData('Text'), this._currentStartIndex, (new Date()).getTime()));
  }

  onKeyDown(event) {
    let which = event.which ? event.which : event.keyCode;
    if (this._currentWord == "") this._currentStartIndex = this.getSelection().start;
    //backspace or delete
    if (which == 8 || which == 46) {
      // if(!is_chrome) this._suppressEvent = true;
      // Block any shorcuts combining Shift or Ctrl with Delete or Backspace button
      if (event.ctrlKey || event.shiftKey) {
        event.preventDefault();
      } else {
        this.deleteChars(event, 1);
      }
    }
    // Block CTRL+Z
    // if ((event.ctrlKey && which == 90) || (which == 9)) {
    //   event.preventDefault();
    // }

    // Block arrow keys
    if ((which >= 33) && (which <= 40)) {
      this.sendCurrentWord();
    }
  }

  onKeyPress(event) {
    if (this._suppressEvent) {
      this._suppressEvent = false;
      return;
    }
    let which = event.which ? event.which : event.keyCode;
    // Block arrow keys in firefox
    //if((event.charCode == 0) && (((event.keyCode >= 33) && (event.keyCode <= 40)) || (event.keyCode == 9))) {
    if ((event.charCode == 0) && (event.keyCode != 13)) {
      return;
    }

    if (!event.ctrlKey && which != 0) {
      let symbol = String.fromCharCode(which).replace("\r", "\n");

      let selection = this.getSelection();
      if (selection.start != selection.end) this.deleteChars(event, 0);

      if (new RegExp("[\ |,|\.|!|\?|\"|:|;|\\n|%|\'|\\|\$|£|<|>|€]", "g").test(symbol) || (which == 13 && !event.ctrlKey)) {
        this.checkDictionnary(symbol, event);
      } else {
        this._currentWord += symbol;
      }
    }
  }

  sendCurrentWord() {
    if (this._currentWord == "") return;

    if (this.onchange) this.onchange(this.messageService.buildUpdateActiveTextMessage(this._currentWord, this._currentStartIndex, (new Date()).getTime()));
    this._currentWord = "";
  }


  getSelection() {
    var el = this.textarea.nativeElement;
    var start = 0, end = 0, normalizedValue, range,
      textInputRange, len, endRange;

    if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
      start = el.selectionStart;
      end = el.selectionEnd;
    } else {
      range = document.createRange();

      if (range && range.parentElement() == el) {
        len = el.value.length;
        normalizedValue = el.value.replace(/\r\n/g, "\n");

        // Create a working TextRange that lives only in the input
        textInputRange = el.createTextRange();
        textInputRange.moveToBookmark(range.getBookmark());

        // Check if the start and end of the selection are at the very end
        // of the input, since moveStart/moveEnd doesn't return what we want
        // in those cases
        endRange = el.createTextRange();
        endRange.collapse(false);

        if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
          start = end = len;
        } else {
          start = -textInputRange.moveStart("character", -len);
          start += normalizedValue.slice(0, start).split("\n").length - 1;

          if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
            end = len;
          } else {
            end = -textInputRange.moveEnd("character", -len);
            end += normalizedValue.slice(0, end).split("\n").length - 1;
          }
        }
      }
    }

    return {
      start: start,
      end: end
    };
  }

  deleteChars(event, nbCharsMin) {
    // Send current word before deleting anything
    this.sendCurrentWord();

    var selection = this.getSelection();
    var which = event.which ? event.which : event.keyCode;
    var nbr = Math.max(nbCharsMin, (selection.end - selection.start));
    var absoluteStart = ((which == 8) && (selection.end == selection.start)) ? (selection.start - nbr) : selection.start;
    if (this.onchange) this.onchange(this.messageService.buildDeleteSuppr(absoluteStart, nbr, (new Date()).getTime()));

    this._currentStartIndex = absoluteStart;
  }

  checkDictionnary(symbol, event) {
    var newWord = "";
    //Vérification de la présence du mot dans le dictionnaire + substitution
    newWord = this.doDictionnarySubstitution(this._currentWord);
    //Modification du délimiteur si nécessaire
    newWord += this.checkSeparator(symbol);

    //Ajout du sérapateur au mot courant
    this._currentWord += symbol;

    if (this._currentWord == newWord) {
      //Pas de modification de la saisie -> envoi du mot
      this.sendCurrentWord();
    } else {
      var oldWord = this._currentWord;
      var textBegin = this.textarea.nativeElement.value.substring(0, this._currentStartIndex);
      var textEnd = this.textarea.nativeElement.value.substring(this._currentStartIndex + oldWord.length - 1);

      if (event.preventDefault) {
        event.preventDefault();
      }

      this._currentWord = newWord;
      this.textarea.nativeElement.value = textBegin + this._currentWord + textEnd;

      this.sendCurrentWord();
      this.setCaretToPos(this._currentStartIndex + newWord.length, false);
    }

  }

  doDictionnarySubstitution(word) {
    var newWord = word;
    var lowerWord = word.toLowerCase();

    if (this.shortcuts[lowerWord] != undefined) {
      if (this.startUpperCase(word)) {
        newWord = this.shortcuts[lowerWord][0].toUpperCase() + this.shortcuts[lowerWord].substr(1, this.shortcuts[lowerWord].length - 1);
      }
      else {
        newWord = this.shortcuts[lowerWord][0] + this.shortcuts[lowerWord].substr(1, this.shortcuts[lowerWord].length - 1);
      }
    }

    return newWord;
  }

  startUpperCase(word) {
    var code = word.charCodeAt(0);
    return (code >= 65 && code <= 90);
  }
  
  setCaretToPos(pos, scroll) {
    this.setSelectionRange(this.textarea.nativeElement, pos, pos, scroll);
  }

  checkSeparator(s) {
    var delimiter = "";
    switch (s) {
      case ':':
        delimiter = ' :';
        break;
      case ';':
        delimiter = ' ;';
        break;
      case '?':
        delimiter = ' ?';
        break;
      case '!':
        delimiter = ' !';
        break;
      case '%':
        delimiter = ' %';
        break;

      case "'":
        delimiter = '\'';
        break;
      case "<":
        delimiter = '< ';
        break;
      case ">":
        delimiter = '> ';
        break;
      case "€":
        delimiter = 'Eur ';
        break;
      default:
        delimiter = s;
        break;
    }
    return delimiter;
  }

  setSelectionRange(input, selectionStart, selectionEnd, scroll) {
    if (input.createTextRange) {
      var range = input.createTextRange();
      range.collapse(true);
      range.moveEnd('character', selectionEnd);
      range.moveStart('character', selectionStart);
      range.select();
    } else if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(selectionStart, selectionEnd);
    }
  }
}
