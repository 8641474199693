import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { Session } from 'src/app/models/session';

@Component({
  selector: 'app-session-archive',
  templateUrl: './session-archive.component.html',
  styleUrls: ['./session-archive.component.sass']
})
export class SessionArchiveComponent implements OnInit {

  currentURL : string;

  currentSession: Session;
  headerList : string
  headerDetails : string

  constructor(private router: Router) { 
    if( this.router.url== "/sessions/documents"){
      this.currentURL = "document";
      this.headerList = "gestion des documents"
      this.headerDetails = "aperçu du document"
    }
    else {
      this.currentURL ="terminate";
      this.headerList = "finalisation des demandes";
      this.headerDetails = "edition du document";
    }
  }

  ngOnInit() {
    
  }

  setCurrentSession(session) {
    this.currentSession = session;
  }



}
