import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import { isNumber } from 'util';

@Injectable()
export class DateformaterService extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('/');
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { day: +(dateParts[0]), month: null, year: null };
      } else if (
        dateParts.length === 2 &&
        isNumber(dateParts[0]) &&
        isNumber(dateParts[1])
      ) {
        return {
          day: +(dateParts[0]),
          month: +(dateParts[1]),
          year: null
        };
      } else if (
        dateParts.length === 3 &&
        isNumber(dateParts[0]) &&
        isNumber(dateParts[1]) &&
        isNumber(dateParts[2])
      ) {
        return {
          day: +(dateParts[0]),
          month: +(dateParts[1]),
          year: +(dateParts[2])
        };
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    if (date) {
      let day = "" + date.day, month = "" + date.month;
      if (isNumber(date.day) && Number(date.day) < 10) {
        day = "0" + date.day
      }
      if (isNumber(date.month) && Number(date.month) < 10) {
        month = "0" + date.month
      }
      return `${isNumber(date.day) ? (day) : ''}/${
        isNumber(date.month) ? (month) : ''
        }/${date.year}`;
    }
    else return ''; 
  }
}
