import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { DictionnaryService } from 'src/app/services/dictionnary.service';
import { ApiService } from 'src/app/services/api.service';
import { User } from 'src/app/models/user';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmWithTwoBtnsComponent } from '../popups/confirm-with-two-btns/confirm-with-two-btns.component';

@Component({
  selector: 'app-dictionary',
  templateUrl: './dictionary.component.html',
  styleUrls: ['./dictionary.component.sass']
})
export class DictionaryComponent implements OnInit {
  index = -1;
  specials;
  personnel;
  currentCle;
  currentDico;
  idDico;
  selectedDico = "personnel";
  isAdmin;
  user: User;
  searchKeyInput: String;
  searchValueInput: String;
  placeholderForSearchByKey = "Recherche par clé";
  placeholderForSearchByValue = "Recherche par valeur";
  shortcutHeader;
  modalRef: NgbModalRef;

  // theme: "Global", public: "Y", type: "specials"
  // theme: "velotypiste", public: "N", type: "personnel"

  @ViewChild('inputCle') inputCle: ElementRef;
  @ViewChild('inputValeur') inputValeur: ElementRef;

  @ViewChild('searchKey') searchKey: ElementRef;
  @ViewChild('searchValue') searchValue: ElementRef;
  constructor(
    private dictionnaryService: DictionnaryService,
    private modalService: NgbModal,
    private renderer: Renderer2,
    private api: ApiService
  ) {
  }

  ngOnInit() {
    // get user roles
    this.api.authenticatedUser.subscribe(user => {
      if (user) {
        this.user = user;
        this.user.fonctions.filter((f) => {
          if (f === "admin") {
            this.isAdmin = true;
          }
        });
      }
    });

    this.dictionnaryService.dictionary.subscribe(res => {
      if (res[0].type == "specials") {
        this.specials = res[0];
        this.personnel = res[1];
      } else {
        this.specials = res[1];
        this.personnel = res[0];
      }
      if (this.selectedDico == "specials") {
        this.getSpecial();
        this.shortcutHeader = "Raccourcis communs"
      } else {
        this.getPersonl();
        this.shortcutHeader = "Raccourcis personnels"
      }
    })
    this.dictionnaryService.getAll();
  }

  getSpecial() {
    this.shortcutHeader = "Raccourcis communs"
    this.selectedDico = "specials";
    this.currentDico = this.specials.raccourcis.sort((a, b) => a.cle.localeCompare(b.cle, 'fr', { ignorePunctuation: true }));
    this.idDico = this.specials.id;
    this.setCurrentCle(null, -1);
    this.clearInputs();
  }

  getPersonl() {
    this.shortcutHeader = "Raccourcis personnels"
    this.selectedDico = "personnel";
    this.currentDico = this.personnel.raccourcis.sort((a, b) => a.cle.localeCompare(b.cle, 'fr', { ignorePunctuation: true }));
    this.idDico = this.personnel.id;
    this.setCurrentCle(null, -1);
    this.clearInputs();
  }

  setCorrectDico() {
    switch (this.idDico) {
      case this.personnel.id: this.currentDico = this.personnel.raccourcis.sort((a, b) => a.cle.localeCompare(b.cle, 'fr', { ignorePunctuation: true })); break;
      case this.specials.id: this.currentDico = this.specials.raccourcis.sort((a, b) => a.cle.localeCompare(b.cle, 'fr', { ignorePunctuation: true })); break;
    }
  }

  clearInputs() {
    this.searchKeyInput = "";
    this.searchValueInput = "";
    if (!!this.inputCle) {
      this.inputCle.nativeElement.value = "";
    }
    if (!!this.inputValeur) {
      this.inputValeur.nativeElement.value = "";
    }
    if (!!this.searchKey) {
      this.searchKey.nativeElement.value = "";
    }
    if (!!this.searchValue) {
      this.searchValue.nativeElement.value = "";
    }
  }

  removeCle() {
    if (this.currentCle) {
      if (this.selectedDico == "personnel") {
        this.dictionnaryService.deletePersonal(this.currentCle).subscribe(() => {
          this.personnel.raccourcis.splice(this.index, 1);
          this.clearInputs();
        }
        );
      } else {
        if (this.isAdmin) {
          this.dictionnaryService.deleteGlobal(this.currentCle).subscribe(() => {
            this.specials.raccourcis.splice(this.index, 1);
            this.clearInputs();
          }
          );
        }
      }
      this.setCorrectDico();
    }
  }

  setCurrentCle(cle: string, i: number) {
    this.index = i;
    this.currentCle = cle;
  }

  addNewShortcut() {
    let cle = this.inputCle.nativeElement.value.toLowerCase();
    let valeur = this.inputValeur.nativeElement.value;
    let id_dictionnaire = this.idDico;
    if (cle != "" && valeur != "") {
      let isDuplicate = false;
      for (let shortcut of this.currentDico) {
        if (shortcut.cle == cle) {
          isDuplicate = true;
          this.handleDuplicateShortcut(cle, valeur, id_dictionnaire);
        }
      }
      if (!isDuplicate) {
        this.dictionnaryService.update(cle, valeur, id_dictionnaire).subscribe(() => {
          this.updateDictionnaryView(cle, valeur, id_dictionnaire);
          this.setCorrectDico();
        });
      }
    }
    this.clearInputs();
  }

  handleDuplicateShortcut(cle, valeur, id_dictionnaire) {
    let config: NgbModalOptions = {
      centered: true,
      backdrop: 'static',
      keyboard: false,
      size: 'sm'
    }
    this.modalRef = this.modalService.open(ConfirmWithTwoBtnsComponent, config);
    this.modalRef.componentInstance.title = 'Information';
    this.modalRef.componentInstance.message = 'duplicate-shortcut';
    this.modalRef.componentInstance.okText = 'Accepter';
    this.modalRef.componentInstance.okAction = () => {
      this.dictionnaryService.update(cle, valeur, id_dictionnaire).subscribe(() => {
        this.updateDictionnaryView(cle, valeur, id_dictionnaire);
        this.setCorrectDico();
        this.modalRef.close('close');
        return;
      });
    }
    this.modalRef.componentInstance.cancelAction = () => this.modalRef.close('close');
    return;
  }

  updateDictionnaryView(cle, valeur, id_dictionnaire) {
    let isNewShortcut = true;
    switch (id_dictionnaire) {
      case this.personnel.id: {
        this.personnel.raccourcis.forEach(shortcut => {
          if (shortcut.cle == cle) {
            shortcut.valeur = valeur;
            isNewShortcut = false;
            return;
          }
        });
        if (isNewShortcut) {
          this.personnel.raccourcis.push({ cle, valeur, id_dictionnaire });
        }
        break;
      }
      case this.specials.id: {
        this.specials.raccourcis.forEach(shortcut => {
          if (shortcut.cle == cle) {
            shortcut.valeur = valeur;
            isNewShortcut = false;
            return;
          }
        });
        if (isNewShortcut) {
          this.specials.raccourcis.push({ cle, valeur, id_dictionnaire });
        }
        break;
      }
    }
  }

  openEditable(elementRef) {
    if (this.isAdmin || this.selectedDico == "personnel") {
      this.renderer.removeAttribute(elementRef, "disabled");
      this.renderer.setStyle(elementRef, "backgroundColor", "white");
    }
  }

  onBlurMethod(key, newValue, elementRef) {
    this.renderer.setAttribute(elementRef, "disabled", "");
    this.renderer.removeStyle(elementRef, "backgroundColor");
    this.dictionnaryService.update(key, newValue, this.idDico).subscribe(() => {
      this.dictionnaryService.getAll();
    });
  }

  searchByKey(searchString: String) {
    this.searchValue.nativeElement.value = "";
    this.setCorrectDico();
    if (!!searchString) {
      this.searchKeyInput = searchString;
      this.currentDico = this.currentDico.filter((record: any) => record.cle.toLowerCase().startsWith(searchString));
    }
  }

  searchByValue(searchString: String) {
    this.searchKey.nativeElement.value = "";
    this.setCorrectDico();
    if (!!searchString) {
      this.searchValueInput = searchString;
      this.currentDico = this.currentDico.filter((record: any) => record.valeur.toLowerCase().startsWith(searchString));
    }
  }
}
