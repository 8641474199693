import { Component, OnInit, Input, OnChanges, SimpleChanges, ElementRef, ViewChild, Renderer2, HostListener } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { User } from 'src/app/models/user';
import { Session } from 'src/app/models/session';
import { formatDate, DatePipe } from '@angular/common';
import { SessionService } from 'src/app/services/session.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-assignment-timeline',
  templateUrl: './assignment-timeline.component.html',
  styleUrls: ['./assignment-timeline.component.sass']
})
export class AssignmentTimelineComponent implements OnInit, OnChanges {
  hours = [];
  currentUser;
  planningUser;
  browserLocale = 'fr';
  currentDate: string;
  hoverSession: Session;
  @ViewChild('sessionDetails') sessionDetails: ElementRef;
  @ViewChild('sessionAssign') sessionAssign: ElementRef;

  @Input() currentSession: Session;

  constructor(
    private api: ApiService,
    private renderer: Renderer2,
    private sessionService: SessionService,
    private datePipe: DatePipe,
    private _translate: TranslateService

  ) {
    this.hours = Array(24)
      .fill(0)
      .map((x, i) => i);
  }

  ngOnInit() {
    this.browserLocale = this._translate.getBrowserLang() === 'en' ? 'en' : 'fr';
    this.hideElement(this.sessionDetails);
    this.hideElement(this.sessionAssign);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentSession.currentValue) {
      this.currentDate = formatDate(changes.currentSession.currentValue.debut, 'EEEE dd MMMM yyy', 'en');
      //get planning
      this.api.getPlanning(this.datePipe.transform(this.currentDate, 'yyyy/MM/dd')).subscribe(planning => {
        this.planningUser = planning;
      });
    }
  }
  hideElement(el: ElementRef) {
    this.renderer.setStyle(el.nativeElement, 'display', 'none');
  }
  showElement(el: ElementRef) {
    this.renderer.setStyle(el.nativeElement, 'display', 'block');
  }

  onMouseEnter(event: MouseEvent, session: Session) {
    this.showElement(this.sessionDetails);
    this.hoverSession = session;
    this.renderer.setStyle(this.sessionDetails.nativeElement, 'left', Math.min(window.innerWidth - 200, event.clientX) + 'px');
    this.renderer.setStyle(this.sessionDetails.nativeElement, 'top', event.clientY + 10 + 'px');
  }
  onMouseLeave() {
    this.hideElement(this.sessionDetails);
  }

  @HostListener('document:click', ['$event.path'])
  public onGlobalClick(targetElementPath: Array<any>) {
    let clickOnAssign = false;
    targetElementPath.forEach(element => {
      if (element.tagName === 'TD') {
        clickOnAssign = true;
      }
    });
    if (!clickOnAssign) {
      this.hideElement(this.sessionAssign);
    }
  }

  changeDay(numberOfDay) {
    let newDate = new Date(this.currentDate);
    newDate.setDate(newDate.getDate() + numberOfDay);
    this.currentDate = formatDate(newDate, 'EEEE dd MMMM yyy', 'en');
  }

  showAssign(user: User, event: MouseEvent) {
    this.showElement(this.sessionAssign);
    this.renderer.setStyle(this.sessionAssign.nativeElement, 'left', Math.min(window.innerWidth - 150, event.clientX) + 'px');
    this.renderer.setStyle(this.sessionAssign.nativeElement, 'top', event.clientY + 10 + 'px');
  }

  assign() {
    this.hideElement(this.sessionAssign);
    //assign and us assign if they same user
    let tmp = this.sessionService.assignSession(
      this.isUserAssigned(this.currentUser) ? "" : this.currentUser.id,
      this.currentSession.id
    ).subscribe(() => {
      this.api.getPlanning(this.datePipe.transform(this.currentDate, 'yyyy/MM/dd')).subscribe(planning => {
        this.planningUser = planning;
      });
      this.sessionService.getSessions();
    });
  }

  isUserAssigned(user) {
    if (user) {
      if (user.sessions.find(x => x.id == this.currentSession.id)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  getSessionShading(theSession: Session, color: string) {
    let left = 0;
    // let leftspam = 0;

    let before = (+ new Date(theSession.debut) - +new Date(this.currentDate)) / (1000 * 60);
    let after = (+ new Date(theSession.fin) - +new Date(this.currentDate)) / (1000 * 60);

    let startMinutes = 60 * 24;
    let endMinutes = 0;
    //start
    if (before > 0 && before < (60 * 24)) {
      startMinutes = before;
      left = (before * 100) / (60 * 24);
      endMinutes = 60 * 24;
    }
    //end
    if (after > 0 && after < (60 * 24)) {
      startMinutes = 0;
      endMinutes = after;
    }
    //middle
    if (before < 0 && after > (60 * 24)) {
      startMinutes = 0;
      endMinutes = 60 * 24;
    }

    //start and end
    if (before > 0 && before < 60 * 24 && (after > 0 && after < 60 * 24)) {
      startMinutes = before;
      endMinutes = after;
    }

    let minutes = Math.max(endMinutes - startMinutes, 0);

    let mintutePercentage = (minutes * 100) / (60 * 24);

    let styles = {
      'background-size': '8.76px 11.63px',
      position: 'absolute',
      background: (color === "black") ?
        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAaUlEQVQoU12PCw2AMAwFbzaGDLCBDXSgAxvYABlgY+RgWxqaLOnn+vqWgBE4+WIFNuCudUk1EZorYCsDF5Aa4OYelAowqCTg0GeodLhZ62yiXL8JTNFTJ9vNv6cG9JvhN68nAYdRaYmeHot9F9X2eFD0AAAAAElFTkSuQmCC') repeat" :
        "rgba(0, 255, 0, 0.3)",
      width: 'calc(' + mintutePercentage + '% + 1px)',
      height: '100%',
      float: 'left',
      left: 'calc(' + left + '% + 1px)',
      "z-index": (color === "black") ? 3 : 2
    };
    if (mintutePercentage <= 0) {
      return "";
    }
    return styles;
  }
}
