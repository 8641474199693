import { FormGroup, Validators, FormControl } from '@angular/forms';

export const PasswordValidation = [
    Validators.required,
    Validators.minLength(8),
    Validators.pattern(/[0-9]/),
    Validators.pattern(/[a-z]/),
    Validators.pattern(/[A-Z]/),
    Validators.pattern(/[!@#$%^&*()\-_=+{};:,<.>]/)
];

export function RepeatPasswordValidator(group: FormGroup) {
    const newPassword = group.controls.newPassword.value;
    const confirmedNewPassword = group.controls.confirmedNewPassword.value; 
    return (newPassword != '' && newPassword === confirmedNewPassword) ? null : { passwordsNotEqual: true };
}


export function noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }