import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators'


@Injectable()
export class LoginGuard implements CanActivate {
  constructor(
    private api: ApiService,
    private router: Router) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    this.api.authenticatedUser.pipe(take(1)).subscribe(user=>{
      if(user)
      this.router.navigate(['/']);
      return false;
    })

    return true;
  }
}
