import { Injectable } from '@angular/core';
import { HTTPService } from './utils';
import { MessageService } from './message.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { PopupHandlerService } from './popup-handler.service';

const SERVER_URL = 'https://velotypie.ingelis.com/transcription';

@Injectable()
export class TranscriptionService extends HTTPService {

  chatMessages: any[] = [];
  channel;
  contentHTML;
  currentSession;
  currentUser;
  caretPosition;
  browserLocale = 'fr';
  dateTimeformat = 'dd MMMM yyyy, HH:mm';
  participants: any[] = [];
  broadcastParticipants: any[] = [];
  hasVelotypisteAndClient: boolean;
  elapsedTimeSeconds;
  isMenuVisible = true;
  constructor(
    private popupHandlerService: PopupHandlerService,
    protected http: HttpClient,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private _translate: TranslateService
  ) {
    super(http);
    this.browserLocale = this._translate.getBrowserLang() === 'en' ? 'en' : 'fr';
    this.dateTimeformat = this.browserLocale === 'en' ? 'dd MMMM yyyy, h:mm a' : 'dd MMMM yyyy, HH:mm';
  }

  setTranscription(s) {
    this.currentSession = s.session;
    this.contentHTML = s.buffer;
    this.channel = s.channel;
    this.currentUser = s.currentUser;
    this.elapsedTimeSeconds = this.convertToElapsedTime(s.duree);
    this.incrementDurationTime();
  }

  updateCurrentParticipants(users) {
    this.participants = [];
    this.broadcastParticipants = [];
    for (let participant of users) {
      if (!!participant.username.id) {
        let user = {
          id: participant.username.id,
          username: participant.username.name,
          type: participant.type
        }
        //avoid duplicates 
        if (this.participants.filter(u => u.id == user.id).length == 0) {
          this.participants.push(user);
        }
      }
      else {
        //avoid duplicates  
        if (this.broadcastParticipants.filter(u => u == participant.username).length == 0) {
          this.broadcastParticipants.push(participant.username);
        }
      }
    }
  }

  getCurrentUserType() {
    return this.isVelotypiste() ? this.messageService.MessageCode.TYPE_VELOTYPISTE : this.messageService.MessageCode.TYPE_CLIENT;
  }

  getCurrUserDisplayName() {
    if (!!this.currentUser.id) {
      // return this.isVelotypiste() ? this.velotypisteDisplayName() : this.clientDisplayName();
      return {
        id: this.currentUser.id,
        name: this.currentUser.prenom + " " + this.currentUser.nom
      };
    }
    else return this.currentUser;
  }

  isVelotypiste() {
    return !!this.currentUser.id && (this.currentSession.velotypiste.id == this.currentUser.id);
  }

  clientDisplayName(): any {
    return this.currentSession.client.prenom + " " + this.currentSession.client.nom;
  }
  velotypisteDisplayName() {
    return this.currentSession.velotypiste.prenom + " " + this.currentSession.velotypiste.nom;
  }

  toggleMenu() {
    this.isMenuVisible = !this.isMenuVisible;
  }

  handleTranscriptionMessage(data) {
    //console.log("handleTranscriptionMessage(data)", data);
    if (data.command) {
      const command = data.command;
      //console.log("handleTranscriptionMessage : command", command);
      //console.log("handleTranscriptionMessage : command.code", command.code);
      switch (command.code) {
        case this.messageService.MessageCode.CODE_TERMINATE:
          //console.log("here ", this.messageService.MessageCode.CODE_TERMINATE);
          if (this.currentUser.type == 'C') {
            this.popupHandlerService.openOneBtnPopupWithCloseAction('session-ended');
          }
          break;
        case this.messageService.MessageCode.CODE_CHAT:
          //console.log("here ", this.messageService.MessageCode.CODE_CHAT);
          let isClientOrVelo = this.currentSession.client.id == this.currentUser.id || this.currentSession.velotypiste.id == this.currentUser.id;
          if (!this.currentSession.publique || isClientOrVelo) {
            this.chatMessages.push({ user: data.user, text: command.contenu });
          }
          break;
        case this.messageService.MessageCode.CODE_USER_PRESENCE:
          //console.log("here ", this.messageService.MessageCode.CODE_USER_PRESENCE);
          //console.log("command connected: ", command.connected);
          this.chatMessages.push({ user: data.user, text: command.connected ? "a rejoint la session" : "a quitté la session" });
          if (!command.connected) {
            //console.log("removing data.user: ", data.user);
            //console.log("participants: ", this.participants);
            this.participants = this.participants.filter(participant => participant.id !== data.user.id);
            //console.log("participants: ", this.participants);
          }
          if (command.connected) {
            //console.log("adding data.user: ", data.user);
            let user = {
              id: data.user.username.id,
              username: data.user.username.name,
              type: data.user.type
            }
            //console.log("adding user: ", user);
            //console.log("participants: ", this.participants);
            //avoid duplicates 
            if (this.participants.filter(u => u.id == user.id).length == 0) {
              this.participants.push(user);
            }
            //console.log("participants: ", this.participants);
          }

          let isVelotypisteAlreadyConnected = this.isVelotypiste() && (command.type_emetteur == this.messageService.MessageCode.TYPE_VELOTYPISTE) /*&& (data.clientId != transcriptionService.clientId)*/;
          if (isVelotypisteAlreadyConnected) {
            if (command.connected) {
              this.popupHandlerService.openOneBtnPopupWithCloseAction('connected-elsewhere');
            }
            return;
          }

          break;
        case this.messageService.MessageCode.CODE_UPDATE_ACTIVE_TEXT:
        case this.messageService.MessageCode.CODE_DELETE_SUPPR:
        case this.messageService.MessageCode.CODE_DELETE_BS:
          this._updateBufferText(command);
          break;
      }
    }

    if (data.users) {
      //console.log("USERS: ", data.users);
      this.updateCurrentParticipants(data.users);
      const userTypes = this.participants.map(user => user.type)
      this.hasVelotypisteAndClient = userTypes.includes('VEL') && userTypes.includes('CLI');
    }

    if (data.messages) {
      data.messages.forEach((message) => {
        this.chatMessages.push(message);
      });

    }
  }

  _updateBufferText(command) {
    if (this.isVelotypiste())
      return;

    this.addBufferCommand(command);
  }

  addBufferCommand(command) {
    if (command.code == this.messageService.MessageCode.CODE_UPDATE_ACTIVE_TEXT) {
      this._appendTextAt(command.content, command.atStartDelta);
    } else {
      this._removeTextAt(command.pos, command.nbr);
    }
  }

  _removeTextAt(pos, nbr) {
    var str1 = this.contentHTML.substring(0, pos);
    var str2 = this.contentHTML.substring(pos + nbr);
    this.caretPosition = pos;
    this.contentHTML = str1 + str2;
  }

  _appendTextAt(content, atStartDelta) {
    //Possible culprit of empty lines "trou" bug when users quit/rejoin 
    // while ((this.contentHTML.length < atStartDelta) && (this.contentHTML.length != 0)) {
    //   this.contentHTML += '\n';
    // }

    var str1 = this.contentHTML.substring(0, atStartDelta);
    var str2 = this.contentHTML.substring(atStartDelta);
    this.caretPosition = atStartDelta + content.length;
    this.contentHTML = str1 + content + str2;
  }

  getDebut() {
    return this.datePipe.transform(this.currentSession.debut.date, this.dateTimeformat, this.currentSession.debut.timezone, this.browserLocale)
  }
  getFin() {
    return this.datePipe.transform(this.currentSession.fin.date, this.dateTimeformat, this.currentSession.fin.timezone, this.browserLocale)
  }

  save(command: any) {
    let body = new FormData();
    body.append('id', this.currentSession.id);
    const sessionDebut = new Date(this.currentSession.debut.date);
    body.append('time', (command.timestamp - sessionDebut.getTime()).toString());
    if (command.code == this.messageService.MessageCode.CODE_UPDATE_ACTIVE_TEXT) {
      body.append('idx', command.atStartDelta.toString());
      body.append('word', command.content.toString());
      this.http
        .post<any>(`${environment.apiUrl}/buffer/write`, body)
        .pipe(
          map((json: any) => {
            this.checkAPIError(json);
            return json;
          })
        ).subscribe();
    } else {
      body.append('idx', command.pos.toString());
      body.append('nbchars', command.nbr.toString());
      this.http
        .post<any>(`${environment.apiUrl}/buffer/delete`, body)
        .pipe(
          map((json: any) => {
            this.checkAPIError(json);
            return json;
          })
        ).subscribe();
    }
  }

  update() {
    let body = new FormData();
    body.append('id', this.currentSession.id);
    body.append('duree', this.currentDurationTime());
    this.http
      .post<any>(`${environment.apiUrl}/buffer/update`, body)
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json;
        })
      ).subscribe();
  }

  convertToElapsedTime(value: any) {
    if (value.match(new RegExp("^[0-9]{2}:[0-9]{2}:[0-9]{2}$"))) {
      const date = value.split(/:/);
      return ((Number(date[0]) * 3600) + (Number(date[1]) * 60) + Number(date[2]));
    } else {
      return 0;
    }
  }

  convertSecondTODurationFormat(secondes) {
    let minutes = Math.floor(secondes / 60);
    let hours = Math.floor(minutes / 60);

    while (minutes >= 60) {
      minutes = minutes - 60;
    }
    while (secondes >= 60) {
      secondes = secondes - 60;
    }
    let countdown: any = { h: hours, m: minutes, s: secondes }
    if (countdown.h < 10) countdown.h = "0" + countdown.h;
    if (countdown.m < 10) countdown.m = "0" + countdown.m;
    if (countdown.s < 10) countdown.s = "0" + countdown.s;

    return countdown.h + ":" + countdown.m + ":" + countdown.s;
  }

  incrementDurationTime() {
    setInterval(() => {
      if (this.hasVelotypisteAndClient) {
        //console.log("hasVelotypisteAndClient: ", this.hasVelotypisteAndClient);
        this.elapsedTimeSeconds += 1;
        this.updateStatusDuration();
      }
    }, 1000)
  }
  updateStatusDuration() {
    if (this.elapsedTimeSeconds % 30 == 0) {
      this.update();
    }
  }
  currentDurationTime() {
    return this.convertSecondTODurationFormat(this.elapsedTimeSeconds);
  }
}

