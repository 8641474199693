import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { ApiService } from 'src/app/services/api.service';
import { MessageService } from 'src/app/services/message.service';
import { SocketService } from 'src/app/services/socket.service';
import { TranscriptionService } from 'src/app/services/transcription.service';

@Component({
  selector: 'app-transcription',
  templateUrl: './transcription.component.html',
  styleUrls: ['./transcription.component.css'],
})


export class TranscriptionComponent implements OnInit {

  @ViewChild('fontColorPicker') fontColorPicker: ElementRef;

  isBroadcastParticipant = false;
  currentSessionId: number;
  currentSession;
  currentUser: User;
  isSessionLoaded = false;
  isMenuVisible = true;
  fontSize = ["10px", "12px", "14px", "18px", "22px", "26px", "30px"];
  colors = [['black', 'white', 'blanchedalmond', 'rgb(255, 128, 0);', 'hsv 100 70 50'], ['red', 'yellow', 'green', 'blue', 'violet']];
  selectedFontColor = "#0000ff";
  selectedFontSize = "12px";
  styles;
  currentParticipant = '';
  hash = '';
  currentMessages = 0;
  constructor(private sessionService: SessionService,
    private route: ActivatedRoute,
    private api: ApiService,
    private messageService: MessageService,
    private router: Router,
    private socketService: SocketService,
    public transcriptionService: TranscriptionService
  ) {
  }

  addCss() {
    let isThereNewMessage = this.currentMessages < this.transcriptionService.chatMessages.length;
    if (!isThereNewMessage)
      return;
    let lastSentMessage = this.transcriptionService.chatMessages[this.transcriptionService.chatMessages.length - 1];
    if (this.isNotMe(lastSentMessage)) {
      this.stopNewMessageAlert();
      return 'new_message'
    }
  }

  stopNewMessageAlert() {
    setTimeout(() => {
      this.currentMessages = this.transcriptionService.chatMessages.length;
    }, 5000)
  }

  ngOnInit() {
    this.joinBroadcastSession();
    if (!this.isBroadcastParticipant) {
      this.joinSession();
      this.currentMessages = this.transcriptionService.chatMessages.length;
    }
  }

  joinBroadcastSession() {
    this.route.queryParams.subscribe((params: Params) => {
      this.currentSessionId = params['sessionId'];
      this.currentParticipant = params['username'];
      this.hash = params['hash'];
      if (!!this.currentParticipant) {
        this.isBroadcastParticipant = true;
        this.sessionService.joinSession(this.currentSessionId, this.hash).subscribe((s) => {
          this.setupSession(s, this.currentParticipant);
        })
      }
    })
  }

  joinSession() {
    this.api.authenticatedUser.subscribe((u) => {
      if (u) {
        this.currentUser = u;
        this.route.queryParams.subscribe((params: Params) => {
          this.currentSessionId = params['sessionId'];
          this.sessionService.joinSession(this.currentSessionId).subscribe((s) => {
            this.setupSession(s, this.currentUser);
          })
        })
      }
    });
  }

  setupSession(s, currentUser) {
    s.currentUser = currentUser;
    this.transcriptionService.setTranscription(s);
    this.socketService.connect();
    this.currentSession = s.session;
    this.setStyle();
    // this.checkUserPermission();
    setTimeout(() => {
      this.isSessionLoaded = true;
    }, 1000)
  }

  isNotMe(chat) {
    return this.currentUser.id != chat.user.id;
  }

  setStyle() {
    this.styles = {
      "color": this.selectedFontColor,
      "font-size": this.selectedFontSize
    }
  }

  openFontColorPicker() {
    this.fontColorPicker.nativeElement.click();
  }

  openTranscriptionInfoPopup() {
    let url = './#/transcription/info?sessionId=' + this.currentSession.id
    if (this.isBroadcastParticipant) {
      url = './#/transcription/info?username=' + this.currentParticipant + '&sessionId=' + this.currentSession.id + '&hash=' + this.hash;
    }
    window.open(url, 'name_info', 'width=860,height=600');
  }

  checkUserPermission() {
    // let id_permission_user: number[] = this.currentSession.participants.map(u => u.id);
    let id_permission_user: number[];

    id_permission_user.push(this.currentSession.velotypiste.id);
    id_permission_user.push(this.currentSession.client.id);

    if (!id_permission_user.includes(this.currentUser.id)) {
      this.router.navigate(['/']);
    }

  }

  sendData(message) {
    if (message != "") {
      const command = this.messageService.buildChatMessage(this.transcriptionService.getCurrentUserType(), message)
      this.socketService.sendData(this.transcriptionService.getCurrUserDisplayName(), command, false)
    }
  }

  menuVisibleChange(event) {
    this.isMenuVisible = !this.isMenuVisible;
  }
}
