import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
    // you can override any of the methods defined in the parent class

    public weekViewHour({ date, locale }: DateFormatterParams): string {
        if (locale === 'en' || locale === "en-US")
            return super.weekViewHour({ date, locale });
        return new DatePipe(locale).transform(date, 'HH:mm');
    }
}
