import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { User } from 'src/app/models/user';
import { TranslateService } from '@ngx-translate/core';
import { noWhitespaceValidator } from 'src/app/services/validator';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  public editUserForm: FormGroup;
  public user: User;
  public updatedUser: User;
  isSaved = false;

  invalidNom = false;
  invalidPrenom = false;
  invalidEmail = false;
  invalidTelephoneFixe = false;
  invalidAdresse = false;
  invalidTelephoneMobile = false;

  constructor(
    private api: ApiService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.editUserForm = new FormGroup({
      'login': new FormControl(null, [Validators.required, noWhitespaceValidator]),
      'nom': new FormControl(null, [Validators.required, noWhitespaceValidator]),
      'prenom': new FormControl(null, [Validators.required, noWhitespaceValidator]),
      'email': new FormControl(null, [Validators.required, Validators.email, noWhitespaceValidator]),
      'telephone_fixe': new FormControl(null, [Validators.required, Validators.pattern(/^[0-9+]*$/), Validators.pattern(/[^. ]*/), noWhitespaceValidator]),
      'adresse': new FormControl(null, [Validators.required, noWhitespaceValidator]),
      'telephone_mobile': new FormControl(null)
    });
    this.api.authenticatedUser.subscribe((user) => {
      if (user) {
        this.user = user;
        this.editUserForm.controls['login'].setValue(this.user.login);
        this.editUserForm.controls['nom'].setValue(this.user.nom);
        this.editUserForm.controls['prenom'].setValue(this.user.prenom);
        this.editUserForm.controls['email'].setValue(this.user.email);
        this.editUserForm.controls['telephone_fixe'].setValue(this.user.telephone_fixe);
        this.editUserForm.controls['adresse'].setValue(this.user.adresse);
        if ((this.user.telephone_velotypie != null && this.user.telephone_velotypie.trim() != "") || (this.editUserForm.value.telephone_mobile != null && this.editUserForm.value.telephone_mobile.trim() != "")) {
          this.editUserForm.controls['telephone_mobile'].setValue(this.user.telephone_velotypie);
          this.editUserForm.get('telephone_mobile').setValidators([Validators.required, Validators.pattern(/^[0-9+]*$/), Validators.pattern(/[^. ]*/), noWhitespaceValidator]);
          this.editUserForm.controls['telephone_mobile'].updateValueAndValidity();
        }
      }
    });
  }

  editUserInfo() {

    if (this.user.telephone_velotypie != null && this.user.telephone_velotypie.trim() === "" && this.editUserForm.value.telephone_mobile != null && this.editUserForm.value.telephone_mobile.trim() === "") {
      this.editUserForm.get('telephone_mobile').setValidators([]);
      this.editUserForm.controls['telephone_mobile'].updateValueAndValidity();
    }

    this.resetValidators();
    this.updatedUser = this.editUserForm.value;
    if (this.isUpdatedUserInfoValid()) {
      this.updatedUser.telephone_velotypie = this.editUserForm.value.telephone_mobile != null ? this.editUserForm.value.telephone_mobile.trim() : "";
      this.api.updateAccount(this.updatedUser).subscribe((res) => {
        this.isSaved = true;
        setTimeout(() => {
          this.isSaved = false;
        }, 5000)
      });
    }
  }

  resetValidators() {
    this.invalidNom = false;
    this.invalidPrenom = false;
    this.invalidEmail = false;
    this.invalidTelephoneFixe = false;
    this.invalidAdresse = false;
    this.invalidTelephoneMobile = false;
  }

  isUpdatedUserInfoValid() {
    let isFormValid = true;
    if (!this.updatedUser.nom || this.editUserForm.controls['nom'].errors != null) {
      this.invalidNom = true;
      isFormValid = false;
    }
    if (!this.updatedUser.prenom || this.editUserForm.controls['prenom'].errors != null) {
      this.invalidPrenom = true;
      isFormValid = false;
    }
    if (!this.updatedUser.email || this.editUserForm.controls['email'].errors != null) {
      this.invalidEmail = true;
      isFormValid = false;
    }
    if (!this.updatedUser.telephone_fixe || this.editUserForm.controls['telephone_fixe'].errors != null) {
      this.invalidTelephoneFixe = true;
      isFormValid = false;
    }
    if (!this.updatedUser.adresse || this.editUserForm.controls['adresse'].errors != null) {
      this.invalidAdresse = true;
      isFormValid = false;
    }
    if (this.user.telephone_velotypie != null && this.user.telephone_velotypie.trim() != "" && this.editUserForm.controls['telephone_mobile'].errors != null) {
      this.invalidTelephoneMobile = true;
      isFormValid = false;
    }
    return isFormValid;
  }

}
