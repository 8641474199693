import { Injectable } from '@angular/core';

import * as SockJS from 'sockjs-client';
import { MessageService } from './message.service';
import { TranscriptionService } from './transcription.service';

const SERVER_URL = 'https://velotypie.ingelis.com/transcription';

@Injectable()
export class SocketService {

  constructor(private messageService: MessageService, private transcriptionService: TranscriptionService) {

  }

  socket: SockJS;
  socketConnectionAttempts = 0;
  private isSocketOpened: boolean = false;

  public connect(): void {
    //console.log('connect()');
    if (!this.isSocketOpened) {
      this.socketConnectionAttempts++;
      //console.log('socketConnectionAttempts: ', this.socketConnectionAttempts);
      this.socket = new SockJS(SERVER_URL);
      //when open
      this.socket.onopen = () => {
        //console.log('Socket is opened');
        this.isSocketOpened = true;
        const channel = this.transcriptionService.channel;
        const currentUserType = this.transcriptionService.getCurrentUserType();
        const user = this.transcriptionService.getCurrUserDisplayName()
        const command = this.messageService.buildInitConnectionMessage(channel, currentUserType);
        const command2 = this.messageService.buildPresence(currentUserType, true, "");

        //console.log("user: ", user);
        //console.log("command2: ", command2);
        this.socket.send(JSON.stringify({ user, command2 }));

        //console.log("command: ", command);
        this.socket.send(JSON.stringify({ user, command }));
      };
      //when close
      this.socket.onclose = () => {
        //console.log('Socket is closed');
        const user = this.transcriptionService.getCurrUserDisplayName()
        const command = this.messageService.buildPresence(this.transcriptionService.getCurrentUserType(), false, "");
        //console.log("user: ", user);
        //console.log("command: ", command);
        this.socket.send(JSON.stringify({ user, command }));
        this.reconnect();
      };

      //when send message
      this.socket.onmessage = (message) => {
        //console.log('onmessage() ', message);
        var json = eval("(" + message.data + ")");
        //console.log('[*] return', json);
        this.transcriptionService.handleTranscriptionMessage(json);
      };
    }
  }

  public reconnect(): void {
    //console.log('reconnect()');
    if (this.isSocketOpened) {
      this.socket = null;
      this.isSocketOpened = false;
    }
    setTimeout(() => {
      this.connect();
    }, this.generateInterval(this.socketConnectionAttempts));
  }

  generateInterval = (attempts) => {
    var maxInterval = (Math.pow(2, attempts) - 1) * 1000;

    if (maxInterval > 30 * 1000) {
      maxInterval = 30 * 1000; // If the generated interval is more than 30 seconds, truncate it down to 30 seconds.
    }

    // generate the interval to a random number between 0 and the maxInterval determined from above
    return Math.random() * maxInterval;
  }

  sendData(user, command, save) {
    this.socket.send(JSON.stringify({ user, command }));
    if (save)
      this.transcriptionService.save(command);
  }
}
