import { Injectable } from '@angular/core';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmWithOneBtnComponent } from '../views/popups/confirm-with-one-btn/confirm-with-one-btn.component';

@Injectable({
  providedIn: 'root'
})
export class PopupHandlerService {
  modalRef;

  constructor(private modalService: NgbModal) { }

  openOneBtnPopup(title: string, errorMessage: string, okText: string, isError: boolean) {
    let config: NgbModalOptions = {
      centered: true,
      backdrop: 'static',
      keyboard: false
    }
    this.modalRef = this.modalService.open(ConfirmWithOneBtnComponent, config);
    this.modalRef.componentInstance.title = title;
    this.modalRef.componentInstance.message = errorMessage;
    this.modalRef.componentInstance.okText = okText;
    this.modalRef.componentInstance.isError = isError;
  }

  openOneBtnPopupWithCloseAction(errorMessage: string) {
    this.openOneBtnPopup('Information', errorMessage, 'fermer', true);
    this.modalRef.result.then(() => window.close());
  }
}
