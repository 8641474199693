import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  NgbModal, NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-with-two-btns',
  templateUrl: './confirm-with-two-btns.component.html',
  styleUrls: ['./confirm-with-two-btns.component.scss']
})
export class ConfirmWithTwoBtnsComponent implements OnInit {

  constructor(public modal: NgbActiveModal) { }
  title = '';
  okText = '';
  message = '';
  okAction: any;
  cancelAction: any;

  ngOnInit() {
  }

  okBtnFunction() {
    this.okAction();
  }
  cancelBtnFunction(){
    this.cancelAction();
  }
  close() {
    this.modal.close('close');
  }
}
