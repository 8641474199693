import { Injectable } from '@angular/core';

@Injectable()




export class MessageService {

  MessageCode =  {
    CODE_INIT : "WEB",
    CODE_CHAT : "CHA",
    CODE_DELETE_BS : 'DBS',
    CODE_DELETE_SUPPR : 'DSP',
    CODE_USER_PRESENCE : 'IMH',
    CODE_UPDATE_ACTIVE_TEXT : 'UAT',
    CODE_UPDATE_FULL_TEXT : 'UFT',
    CODE_UPDATE_VALIDATED_TEXT : 'UVT',

    CODE_TERMINATE : 'TER',
    TYPE_CLIENT : 'CLI',
    TYPE_CORRECTEUR : 'COR',
    TYPE_VELOTYPISTE : 'VEL'
  }

  constructor() { }

  buildInitConnectionMessage(channel, userType) {
    return { "code": this.MessageCode.CODE_INIT, channel: channel, type: userType }
  }

  buildDeleteBackspace = function (pos, nbr, noSeq) {
    return { "code": this.MessageCode.CODE_DELETE_BS, "pos": pos, "nbr": nbr, "timestamp": noSeq };
  }

  buildDeleteSuppr = function (pos, nbr, noSeq) {
    return { "code": this.MessageCode.CODE_DELETE_SUPPR, "pos": pos, "nbr": nbr, "timestamp": noSeq };
  }

  buildPresence = function (type_emetteur, connected, status) {
    return { "code": this.MessageCode.CODE_USER_PRESENCE, "type_emetteur": type_emetteur, "connected": connected, "status": status };
  }

  // buildSetActiveTextBufferSizeMessage = function (newSize) {
  //   return { "code": this.MessageCode.CODE_SET_ACTIVETEXT_BUFFER_SIZE, "newSize": newSize };
  // }

  buildUpdateActiveTextMessage = function (newText, OldActiveTextEnd, noSeq) {
    return { "code": this.MessageCode.CODE_UPDATE_ACTIVE_TEXT, "content": newText, "atStartDelta": OldActiveTextEnd, "timestamp": noSeq };
  }

  buildUpdateValidatedTextMessage = function (newText, OldActiveTextEnd, noSeq) {
    return { "code": this.MessageCode.CODE_UPDATE_VALIDATED_TEXT, "content": newText, "atStartDelta": OldActiveTextEnd, "timestamp": noSeq };
  }

  buildContentMessage = function (text, noSeq) {
    return { "code": this.MessageCode.CODE_UPDATE_FULL_TEXT, "content": text, "timestamp": noSeq };
  }
  buildChatMessage = function (type_emetteur, contenu) {
    return { "code": this.MessageCode.CODE_CHAT, "type_emetteur": type_emetteur, "contenu": contenu };
  }
}
