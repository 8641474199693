import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { Session } from 'src/app/models/session';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']

})
export class MainComponent implements OnInit {
  currentUser: User;
  sessions: Session[] = [];
  isCalendar = false;
  announcementMessage = "";
  constructor(
    private api: ApiService,
    private router: Router,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.api.authenticatedUser.subscribe((user) => {
      if (user) {
        this.currentUser = user;
      }
    });
    this.router.events.subscribe(() => {
      this.isCalendar = this.router.url === "/calendar";
    })
  }

  setAnnouncementMessage(message) {
    this.isCalendar = this.router.url === "/calendar";
    this.announcementMessage = message;
  }

  getNumberOfSession(filterByStatus) {
    return this.sessions.filter(
      session => filterByStatus.indexOf(session.etat) !== -1
    ).length;
  }

  logout() {
    this.api.logout().subscribe(() => {
      this.router.navigate(['/login']);
    });
  }

  openHelpFile() {
    let target = environment.url + "files/user-guide.pdf"
    window.open(target, '_blank');
  }
}
