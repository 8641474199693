import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersListComponent } from './views/users-list/users-list.component';
import { GetClientsResolver, GetPersonnelsResolver } from './resolvers';
import { DatePipe } from '@angular/common';
import { LoginComponent } from './views/credentials/login/login.component';
import { MainComponent } from './views/main/main.component';
import { UserDetailsComponent } from './views/user-details/user-details.component';
import { RoleGuard } from './guards/role-guard.service';
import { LoginGuard } from './guards/login-guard.service';
import { SessionsComponent } from './views/sessions/sessions.component';
import { AssignmentsComponent } from './views/assignments/assignments.component';
import { DictionaryComponent } from './views/dictionary/dictionary.component';
import { CalendarComponent } from './views/calendar/calendar.component';
import { TranscriptionComponent } from './views/transcription/transcription.component';
import { SessionArchiveComponent } from './views/session-archive/session-archive.component';
import { TranscriptionInfoComponent } from './views/transcription/transcription-info/transcription-info.component';
import { ParticipateBroadcastComponent } from './views/participate-broadcast/participate-broadcast.component';

const routes: Routes = [
  { path: 'join', component: ParticipateBroadcastComponent },

  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
  {
    path: '',
    component: MainComponent,
    children: [
      { path: '', redirectTo: 'sessions', pathMatch: 'full' },
      {
        path: 'sessions',
        children: [
          {
            path: '',
            component: SessionsComponent,
            canActivate: [RoleGuard],
            data: {
              type: 'P',
              fonctions: ['admin', 'client'],
              gestion_demandes: 'Y'
            }
          },
          {
            path: 'assignment',
            component: AssignmentsComponent,
            canActivate: [RoleGuard],
            data: { type: 'P', fonctions: ['admin'], gestion_demandes: 'Y' },
            resolve: {
              datas: GetPersonnelsResolver
            }
          },
          {
            path: 'fenced',
            component: SessionArchiveComponent,
            canActivate: [RoleGuard],
            data: {
              type: 'P',
              fonctions: ['velotypiste', 'admin', 'correcteur']
            }
          },
          {
            path: 'documents',
            component: SessionArchiveComponent,
            canActivate: [RoleGuard],
            data: {
              type: 'P',
              fonctions: ['admin', 'client'],
              gestion_demandes: 'Y'
            }
          }
        ]
      },
      {
        path: 'clients',
        canActivate: [RoleGuard],
        data: { type: 'P', fonctions: ['admin'], gestion_clients: 'Y' },
        component: UsersListComponent,
        resolve: {
          datas: GetClientsResolver
        }
      },
      {
        path: 'users',
        canActivate: [RoleGuard],
        data: { type: 'P', fonctions: ['admin'], gestion_personnel: 'Y' },
        component: UsersListComponent,
        resolve: {
          datas: GetPersonnelsResolver
        }
      },
      {
        path: 'dictionnary',
        component: DictionaryComponent,
        canActivate: [RoleGuard],
        data: { type: 'P', fonctions: ['velotypiste', 'correcteur'] }
      },
      {
        path: 'calendar',
        component: CalendarComponent,
        canActivate: [RoleGuard],
        data: { fonctions: ['client', 'velotypiste'] }
      },
      {
        path: 'account',
        children: [
          { path: '', redirectTo: 'profile', pathMatch: 'full' },
          { path: 'profile', component: UserDetailsComponent }
        ],
        canActivate: [RoleGuard],
        data: { fonctions: ['admin', 'client', 'velotypiste', 'correcteur'] }
      }
    ]
  },
  {
    path: 'transcription',
    component: TranscriptionComponent,
    canActivate: [RoleGuard],
    data: { fonctions: ['admin', 'velotypiste', 'client'] }
  },
  {
    path: 'transcription/info',
    component: TranscriptionInfoComponent,
    canActivate: [RoleGuard],
    data: { fonctions: ['admin', 'velotypiste', 'client'] }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [DatePipe, GetClientsResolver, GetPersonnelsResolver, RoleGuard, LoginGuard]
})
export class AppRoutingModule { }
