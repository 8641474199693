import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranscriptionService } from '../../services/transcription.service';

@Component({
  selector: 'app-participate-broadcast',
  templateUrl: './participate-broadcast.component.html',
  styleUrls: ['./participate-broadcast.component.scss']
})
export class ParticipateBroadcastComponent implements OnInit {

  isSessionLoaded = true;
  username = "";
  constructor(
    private route: ActivatedRoute,
    public transcriptionService: TranscriptionService,
    private router: Router) { }

  ngOnInit() {
  }

  connect() {
    if (this.username && this.username.trim()) {
      this.isSessionLoaded = false;
      this.route.queryParams.subscribe((params: Params) => {
        let data = {
          "username": this.username,
          "sessionId": params['sessionId'],
          "hash": params['hash']
        };
        this.router.navigate(['/transcription'], { queryParams: data });
        setTimeout(() => {
          this.isSessionLoaded = true;
        }, 1000)
      })
    }
  }
}
