import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Data } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { User } from '../models/user';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
    private api: ApiService,
    private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let isBoradcastParticipant = next.queryParams && next.queryParams.username && next.queryParams.username.trim();
    if (isBoradcastParticipant) {
      return true;
    }
    let currentUser;
    return this.api.getMe().pipe(
      map(user => {
        currentUser = user;
        let isAllowed = false;
        let isNoUserTypeRestirction = !next.data.type
        if (isNoUserTypeRestirction || currentUser.type == 'C') {
          isAllowed = this.hasRole(currentUser.fonctions, next.data.fonctions);
        }
        else if ((currentUser.type == next.data.type) && this.hasRole(currentUser.fonctions, next.data.fonctions) && this.hasRights(currentUser, next.data)) {
          isAllowed = true;
        }
        return isAllowed
      }),
      tap(allowed => {
        if (!allowed) {
          this.redirectToHomePage(currentUser);
        }
      })
    );
  }

  private getHomePage(currentUser){
    let homepage = "/calendar";
    let isAdmin = currentUser.type == "P" && currentUser.fonctions.indexOf['admin'] != -1;
    if (isAdmin && currentUser.gestion_demandes === 'Y') {
      homepage = "/sessions";
    }
    else if (isAdmin && currentUser.gestion_clients === 'Y') {
      homepage = "/clients";
    }
    else if (isAdmin && currentUser.gestion_personnel === 'Y') {
      homepage = "/users";
    }
    return homepage
  }

  private redirectToHomePage(currentUser) {
    let homepage = this.getHomePage(currentUser); 
    this.router.navigate([homepage]);
  }

  private hasRights(user: User, requiredRights: Data) {
    let isNoRequiredRights = !requiredRights.gestion_clients && !requiredRights.gestion_demandes && !requiredRights.gestion_personnel;
    if (isNoRequiredRights) {
      return true;
    }
    if (requiredRights.gestion_clients === 'Y' && user.gestion_clients === 'Y')
      return true;
    if (requiredRights.gestion_demandes === 'Y' && user.gestion_demandes === 'Y')
      return true;
    if (requiredRights.gestion_personnel === 'Y' && user.gestion_personnel === 'Y')
      return true;
    return false;
  }

  private hasRole(userRoles: string[], requiredRoles: string[]) {
    if (!requiredRoles)
      return false;
    for (var i = 0; i < requiredRoles.length; i++) {
      let loggedUserHasRequiredRoles = userRoles.indexOf(requiredRoles[i]) != -1;
      if (loggedUserHasRequiredRoles) {
        return true;
      }
    }
    return false;
  }
}
