import { Component, OnInit, Injectable } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-confirm-with-one-btn',
  templateUrl: './confirm-with-one-btn.component.html',
  styleUrls: ['./confirm-with-one-btn.component.sass']
})
export class ConfirmWithOneBtnComponent implements OnInit {
  isError = false;
  title = '';
  okText = '';
  message = '';
  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
  }

  okBtnFunction() {
    this.modal.close('close');
  }


}
