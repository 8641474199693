import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable()
export class HTTPService {
    constructor(protected http: HttpClient) { }

    protected toHttpParams(params: object): HttpParams {
        let queryParams = new HttpParams();
        if (params) {
            Object.keys(params).forEach(element => {
                if (params[element] !== undefined) {
                    queryParams = queryParams.append(element, params[element]);
                }
            });
        }
        return queryParams;
    }

    protected checkAPIError(json: any) {
        if (json.code !== 0) throw json;
    }

    protected handleError(error: any) {
        return of(error);
    }
}

export const ObserveResponse: object = { observe: 'response' };
export const ObserveBody: object = { observe: 'body' };
