import { CalendarEvent, CalendarDateFormatter } from 'angular-calendar';
import { DayViewHourSegment } from 'calendar-utils';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit, Renderer2, ElementRef, ViewChild, Input, OnChanges, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { formatDate, DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { SessionStatus, Session } from 'src/app/models/session';
import { CreateSessionPopupHandlerService } from 'src/app/services/create-session-popup-handler.service';
import { SessionService } from 'src/app/services/session.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TerminateSessionService } from 'src/app/services/terminate-session-service';
import { TranscriptPopupComponent } from '../popups/transcript-popup/transcript-popup.component';
import { Router } from '@angular/router';
import { addDays, addMinutes } from 'date-fns';
import { finalize, takeUntil } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { ContextMenuService } from 'ngx-contextmenu';
import { User } from 'src/app/models/user';
import { CustomDateFormatter } from 'src/app/services/custom-date-formatter.provider';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { UserSessionStatus } from 'src/app/models/userSessionStatus';

const DAY: number = 24 * 60 * 60 * 1000;  // day in milliseconds
function floorToNearest(amount: number, precision: number) {
  return Math.floor(amount / precision) * precision;
}

function ceilToNearest(amount: number, precision: number) {
  return Math.ceil(amount / precision) * precision;
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class CalendarComponent implements OnInit, OnChanges {

  @Input()
  calendarUser: User;
  currentUser: User;
  dragToCreateActive = false;
  isCalendar = false;
  viewDate = new Date();
  events: CalendarEvent[] = [];
  browserLocale = 'fr';
  timeFormat = 'HH:mm';
  currentStartDate = new Date();
  currentEndDate = new Date();
  shownDates: string;
  calendar;
  isCurrentUserClient = false;
  isCurrentUserAdmin = false;
  isCurrentUserVelotypiste = false;
  selectedEvent: any = {};
  SessionStatus = SessionStatus
  sessionStatusValues = Object.values(SessionStatus);
  tooltip: any;
  weekEvent: any;
  sessions: Session[] = [];
  transcriptHTML: any;
  userSessionStatus: UserSessionStatus;

  @ViewChild('eventDetailsPopup') eventDetailsPopup: ElementRef;
  buggedEvents: CalendarEvent<any>[];
  users: User[] = [];
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.calendarUser.firstChange) {
      this.calendarUser = changes.calendarUser.currentValue;
      this.viewDate = new Date(this.currentStartDate);
      this.sharedDataservice.changeUserDateChoice(this.viewDate)
      this.getEvents();
    }
  }

  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private _translate: TranslateService,
    private createSessionPopupHandlerService: CreateSessionPopupHandlerService,
    private sessionService: SessionService,
    private modalService: NgbModal,
    private terminateSessionService: TerminateSessionService,
    private renderer: Renderer2,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private contextMenuService: ContextMenuService,
    private sharedDataservice: SharedDataService) { }


  ngOnInit() {
    this.isCalendar = this.router.url === "/calendar";
    this.hideElement(this.eventDetailsPopup);
    this.browserLocale = this._translate.getBrowserLang() === 'en' ? 'en' : 'fr';
    this.timeFormat = this.browserLocale === 'en' ? 'h:mm a' : 'HH:mm';

    this.api.authenticatedUser.subscribe((user) => {
      if (user) {
        this.currentUser = user
        if (this.isCalendar) {
          this.calendarUser = this.currentUser;
        }
        this.detectCurrentUserRole();
      }
    });

    this.sharedDataservice.userDateChoice.subscribe(date => {
      this.viewDate = date;
    })

    this.sharedDataservice.currentUserSessionStatus.subscribe(status => {
      this.userSessionStatus = status;
    })

    this.currentStartDate = this.getMonday(this.viewDate);
    this.currentEndDate = this.getSunday(this.viewDate);
    this.resetShownDates();
    this.sessionService.sessions.subscribe(sessions => {
      this.sessions = sessions;
    });
    this.sessionService.getSessions();
  }

  detectCurrentUserRole() {
    if (this.currentUser.type == 'C') {
      this.isCurrentUserClient = true;
    }
    if (this.currentUser.type == 'P' && this.currentUser.fonctions.indexOf('admin') != -1) {
      this.isCurrentUserAdmin = true;
    }
    if (this.currentUser.type == 'P' && this.currentUser.fonctions.indexOf('velotypiste') != -1) {
      this.isCurrentUserVelotypiste = true;
    }
  }


  adjustCalendar() {
    let isWrapperDivPresent = document.getElementById("wrapper");
    if (isWrapperDivPresent) {
      document.getElementById("wrapper").scrollTo(0, 310);
    }
    else {
      let cal_time_events = document.getElementsByClassName("cal-week-view")[0].lastChild;
      let wrapper_div = document.createElement('div');
      wrapper_div.setAttribute("id", "wrapper");
      wrapper_div.setAttribute("style", "height:445px; overflow-x:hidden;");
      cal_time_events.parentNode.insertBefore(wrapper_div, cal_time_events);
      wrapper_div.appendChild(cal_time_events);
      wrapper_div.scrollTo(0, 310);
    }
  }

  getHeader() {
    let componentHeader = this.isCalendar ? "Agenda" : "Planning"
    return componentHeader;
  }

  getEventTitlePreview(weekEvent: any) {
    if (this.isAdminViewingClientCalendar() || this.isClientViewingHisOwnCalendar()) {
      return weekEvent.title;
    }
    if (this.isAdminViewingStaffCalendar() || this.isStaffViewingHisOwnCalendar()) {
      return weekEvent.clientDisplayName;
    }
  }

  isBugged() {
    this.buggedEvents = this.events.filter((event: CalendarEvent) => event.meta != null)
    return this.buggedEvents.length > 0;
  }

  deleteBuggedEvent() {
    let buggedEventIndex = this.events.indexOf(this.buggedEvents[0]);
    delete this.events[buggedEventIndex];
  }

  canCurrentUserCreateEventInCurrentCalendar() {
    return this.isAdminViewingClientCalendar() || this.isClientViewingHisOwnCalendar();
  }

  isAdminViewingCalendar() {
    return !this.isCalendar && this.isCurrentUserAdmin;
  }
  isAdminViewingClientCalendar() {
    return (this.isAdminViewingCalendar() && this.calendarUser.type == 'C');
  }
  isAdminViewingStaffCalendar() {
    return (this.isAdminViewingCalendar() && this.calendarUser.type == 'P');
  }

  isStaffViewingHisOwnCalendar() {
    return this.isCalendar && !this.isCurrentUserClient;
  }
  isClientViewingHisOwnCalendar() {
    return this.isCalendar && this.isCurrentUserClient;
  }

  dragToCreateEvent(
    segment: DayViewHourSegment,
    mouseDownEvent: MouseEvent,
    segmentElement: HTMLElement
  ) {
    if (!this.canCurrentUserCreateEventInCurrentCalendar()) {
      return;
    }

    if (this.isBugged()) {
      this.deleteBuggedEvent();
      // this.getEvents();
      return;
    }
    let currentDate = new Date(segment.date);
    let endDate = new Date(segment.date);
    endDate.setMinutes(endDate.getMinutes() + 30);
    const dragToSelectEvent: CalendarEvent = {
      id: this.events.length,
      title: formatDate(currentDate, this.timeFormat, 'en') + ' - ' + formatDate(endDate, this.timeFormat, 'en'),
      start: segment.date,
      meta: {
        tmpEvent: true
      }
    };
    this.events = [...this.events, dragToSelectEvent];
    const segmentPosition = segmentElement.getBoundingClientRect();
    this.dragToCreateActive = true;
    fromEvent(document, 'mousemove')
      .pipe(
        finalize(() => {
          delete dragToSelectEvent.meta.tmpEvent;
          this.dragToCreateActive = false;
          this.refresh();
        }),
        takeUntil(fromEvent(document, 'mouseup'))
      )
      .subscribe((mouseMoveEvent: MouseEvent) => {
        const minutesDiff = ceilToNearest(
          mouseMoveEvent.clientY - segmentPosition.top,
          30
        );
        this.hideElement(this.eventDetailsPopup);

        const daysDiff =
          floorToNearest(
            mouseMoveEvent.clientX - segmentPosition.left,
            segmentPosition.width
          ) / segmentPosition.width;

        const newEnd = addDays(addMinutes(segment.date, minutesDiff), daysDiff);
        if (newEnd.getDate() == segment.date.getDate()) {
          dragToSelectEvent.end = newEnd;
          dragToSelectEvent.title = formatDate(dragToSelectEvent.start, this.timeFormat, 'en') + ' - ' + formatDate(dragToSelectEvent.end, this.timeFormat, 'en');
        }
        this.refresh();

      }, () => { }, () => {
        let newSession = new Session();
        if (null != dragToSelectEvent.start) {
          newSession.id_client = this.calendarUser.id;
          newSession.client_display_name = this.calendarUser.prenom + " " + this.calendarUser.nom
          newSession.debut = dragToSelectEvent.start;
          newSession.fin = dragToSelectEvent.end == null ? dragToSelectEvent.start : dragToSelectEvent.end;
          this.createSessionPopupHandlerService.createSessionFromCalendar(this.isCurrentUserClient, this.calendarUser, newSession, this.getEvents.bind(this));
        }
      }
      );
  }
  private refresh() {
    this.events = [...this.events];
    this.cdr.detectChanges();
  }
  getColor(color: string) {
    switch (color) {
      case 'mauve': return { primary: '#9595DD', secondary: '#9595DD' };        // à traiter
      case 'red': return { primary: '#FD5C31', secondary: '#FD5C31' };          // refusée
      case 'blue': return { primary: '#64B8D1', secondary: '#64B8D1' };         // validée
      case 'grey': return { primary: '#D3D3D3', secondary: '#D3D3D3' };         // archivée
      case 'pink': return { primary: '#F69999', secondary: '#F69999' };         // annulée
      case 'pale_green': return { primary: '#C5F6AD', secondary: '#C5F6AD' };   // confirmée
      case 'yellow': return { primary: '#F1E747', secondary: '#F1E747' };       // terminée
    }
  }

  hideElement(el: ElementRef) {
    this.renderer.setStyle(el.nativeElement, 'display', 'none');
  }

  showElement(el: ElementRef) {
    this.renderer.setStyle(el.nativeElement, 'display', 'block');
  }

  showEventDetails(event: MouseEvent, session: any) {
    this.selectedEvent = session;
    this.showElement(this.eventDetailsPopup);
    this.renderer.setStyle(this.eventDetailsPopup.nativeElement, 'left', event.clientX + 10 + 'px');
    this.renderer.setStyle(this.eventDetailsPopup.nativeElement, 'top', event.clientY + 10 + 'px');
  }

  onMouseLeave() {
    this.hideElement(this.eventDetailsPopup);
  }

  getCalendarData() {
    //to prevent duplicate session
    let eventId = [];
    this.events = [];
    for (var i = 0; i < 7; i++) {
      for (var j = 0; j < this.calendar[i].events.length; j++) {
        let currentEvent = this.calendar[i].events[j];
        if (!eventId.includes(currentEvent.id)) {
          eventId.push(currentEvent.id);
          let event = this.buildEventItem(currentEvent);
          this.events.push(event);
        }
      }
    }
  }

  buildEventItem(currentEvent) {
    return {
      id: currentEvent.id,
      client: currentEvent.client,
      title: currentEvent.intitule,
      start: new Date(currentEvent.debut),
      color: this.getColor(currentEvent.color),
      end: new Date(currentEvent.fin),
      time: formatDate(currentEvent.debut, this.timeFormat, 'en') + ' - ' + formatDate(currentEvent.fin, this.timeFormat, 'en'),
      status: currentEvent.etat,
      public: currentEvent.publique === 'Y' ? true : false,
      clientDisplayName: this.getClientDisplayName(currentEvent),
      isParticipant: this.isParticipant(currentEvent.is_participant),
      hasAccess: currentEvent.acces_client === 'Y' ? true : false,
      allDay: new Date(currentEvent.debut).getDate() != new Date(currentEvent.fin).getDate()
    }
  }

  getClientDisplayName(currentEvent) {
    if (this.isCurrentUserVelotypiste && !!currentEvent.client) {
      return currentEvent.client.prenom + " " + currentEvent.client.nom;
    }
    if (this.isAdminViewingClientCalendar() || this.isClientViewingHisOwnCalendar()) {
      return this.calendarUser.prenom + " " + this.calendarUser.nom;
    }
    if (this.isAdminViewingStaffCalendar || this.isStaffViewingHisOwnCalendar()) {
      return currentEvent.prenom_client + " " + currentEvent.nom_client;
    }
  }

  isParticipant(isParticipant: any) {
    if (isParticipant == null) {
      return false;
    }
    return isParticipant;
  }

  public displayContextMenuUsingLeftClick($event: KeyboardEvent, item: any): void {
    this.contextMenuService.show.next({
      anchorElement: $event.target,
      // Optional - if unspecified, all context menu components will open
      // contextMenu: this.contextMenu,
      event: <any>$event,
      item: item,
    });
    $event.preventDefault();
    $event.stopPropagation();
  }

  getBackgroundColor(status: SessionStatus) {
    const bgColors = {};
    bgColors[SessionStatus.PENDING] = '#9595dd';
    bgColors[SessionStatus.CONFIRMED] = '#c5f6ad';
    bgColors[SessionStatus.CANCELED] = '#f69999';
    bgColors[SessionStatus.REFUSED] = '#fd5c31';
    bgColors[SessionStatus.ARCHIVED] = '#d3d3d3';
    bgColors[SessionStatus.VALIDATED] = '#64b8d1';
    bgColors[SessionStatus.FINISHED] = '#f1e747';
    return bgColors[status];
  }

  getMonday = function (d: Date) {
    const dayNumber = (d.getDay() == 0 ? 7 : d.getDay()); // if sunday make it 7
    return new Date(new Date(d).getTime() - dayNumber * DAY + DAY);
  }

  getSunday(d: Date) {
    const dayNumber = (d.getDay() == 0 ? 7 : d.getDay()); // if sunday make it 7
    return new Date(new Date(d).getTime() - dayNumber * DAY + 7 * DAY)
  }

  changeDay(numberOfDay) {
    this.currentStartDate.setDate(this.currentStartDate.getDate() + numberOfDay);
    this.currentEndDate.setDate(this.currentEndDate.getDate() + numberOfDay);
    this.viewDate = new Date(this.currentStartDate);
    this.sharedDataservice.changeUserDateChoice(this.viewDate)
    this.resetShownDates();
  }

  getToday() {
    this.viewDate = new Date();
    this.sharedDataservice.changeUserDateChoice(this.viewDate)
    this.currentStartDate = this.getMonday(this.viewDate);
    this.currentEndDate = this.getSunday(this.viewDate);
    this.resetShownDates();
  }

  private resetShownDates() {
    let monday = formatDate(this.currentStartDate, 'dd/MM/yyy', 'en');
    let sunday = formatDate(this.currentEndDate, 'dd/MM/yyy', 'en');
    this.shownDates = monday + " - " + sunday;
    this.getEvents();
  }

  private getEvents() {
    this.api.getCalendar(this.calendarUser.id, this.datePipe.transform(this.currentStartDate, 'dd-MM-yyy'),
      this.datePipe.transform(this.currentEndDate, 'dd-MM-yyy')).subscribe(
        calendar => {
          this.calendar = calendar;
          this.getCalendarData();
          this.adjustCalendar();
        });
  }

  showEvent() {
    this.doAction('show', this.selectedEvent);
  }

  doAction(action: string, item: any) {
    switch (action) {
      case 'join': {
        let currentSession = this.sessions.find(s => s.id == item.id);
        if (this.sessionService.isCurrentUserAllowedToJoinSession(currentSession, this.currentUser.id) && !this.sessionService.isAlreadyInASession(this.currentUser.id)) {
          this.sessionService.joinSession(item.id).subscribe(() => {
            this.sessionService.openTranscriptionWindow(this.currentUser.id, item.id);
          });
        }
        break;
      }
      case 'show': {
        let currentSession = this.sessions.find(s => s.id == item.id)
        if (currentSession != null) {
          this.createSessionPopupHandlerService.viewSession(this.isCurrentUserClient, currentSession, this.selectedEvent.client);
        }
        break;
      }
      case 'modify': {
        let currentSession = this.sessions.find(s => s.id == item.id)
        if (currentSession != null) {
          this.createSessionPopupHandlerService.editSession(this.isCurrentUserClient, currentSession, this.getEvents.bind(this));
        }
        break;
      }
      case 'cancel': {
        this.cancelSession(item.id);
        break;
      }
      case 'terminate': {
        this.terminateSessionService.terminateSessionPopup(item.id, this.getEvents.bind(this))
        break;
      }
      case 'accessDocument': {
        this.accessDocument(item.id, item.title);
        break;
      }
      case 'duplicate': {
        let currentSession = this.sessions.find(s => s.id == item.id)
        if (currentSession != null) {
          this.createSessionPopupHandlerService.duplicateSession(this.isCurrentUserClient, currentSession, this.getEvents.bind(this));
        }
        break;
      }
    }
  }

  isAllowedStatus(allowedStatus: SessionStatus[]) {
    for (let status of allowedStatus) {
      if (status === this.selectedEvent.status)
        return true;
    }
    return false;
  }

  isAllowedToDoAction(action: string) {
    if (this.selectedEvent == null)
      return false;
    let allowedStatus: SessionStatus[];
    switch (action) {
      case 'join': {
        allowedStatus = [SessionStatus.CONFIRMED];
        let currentSession = this.sessions.find(s => s.id == this.selectedEvent.id)
        return this.isAllowedStatus(allowedStatus) && this.sessionService.isCurrentUserAllowedToJoinSession(currentSession, this.currentUser.id);
      }
      case 'modify': case 'cancel': {
        allowedStatus = [SessionStatus.PENDING, SessionStatus.CONFIRMED, SessionStatus.VALIDATED];
        return this.isAllowedStatus(allowedStatus) && !this.selectedEvent.isParticipant && this.isCurrentUserClient;
      }
      case 'terminate': {
        allowedStatus = [SessionStatus.CONFIRMED, SessionStatus.VALIDATED];
        return this.isAllowedStatus(allowedStatus) && !this.isCurrentUserClient;
      }
      case 'accessDocument': {
        allowedStatus = [SessionStatus.ARCHIVED];
        return this.isAllowedStatus(allowedStatus) && (this.selectedEvent.hasAccess || !this.isCurrentUserClient);
      }
      case 'duplicate': {
        return (this.isCurrentUserClient && !this.selectedEvent.isParticipant) || this.isCurrentUserAdmin;
      }
    }
  }

  cancelSession(sessionId: number) {
    const getEventsFunc = this.getEvents.bind(this);
    this.sessionService.cancelSessionHandler(sessionId, getEventsFunc);
  }

  accessDocument(sessionId: number, title: string) {
    this.sessionService.getArchive(sessionId).subscribe(data => {
      this.transcriptHTML = data;

      const modalRef = this.modalService.open(TranscriptPopupComponent,
        {
          centered: true,
          backdrop: 'static',
          size: 'lg'
        });
      modalRef.componentInstance.htmlContent = this.transcriptHTML;
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isView = true;
      modalRef.componentInstance.config = {
        editable: false,
        showToolbar: false,
        defaultFontSize: '2',
        spellcheck: true,
        height: '15rem',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        toolbarPosition: 'top',
        defaultFontName: 'Times New Roman'
      };
    })
  }

  openCreateNewSession() {
    this.createSessionPopupHandlerService.openCreateNewSessionWithRefresh(this.isCurrentUserClient, this.getEvents.bind(this));
  }
}
