import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { User } from 'src/app/models/user';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Session, SessionStatus } from 'src/app/models/session';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { SessionService } from 'src/app/services/session.service';
import { DateformaterService } from 'src/app/services/dateformater.service';
import { DatepickerlocalService, I18n } from 'src/app/services/datepickerlocal.service';
import { formatDate } from '@angular/common';
import { PopupHandlerService } from 'src/app/services/popup-handler.service';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-create-session-popup',
  templateUrl: './create-session-popup.component.html',
  styleUrls: ['./create-session-popup.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: DateformaterService },
    I18n,
    { provide: NgbDatepickerI18n, useClass: DatepickerlocalService }
  ]
})
export class CreateSessionPopupComponent implements OnInit {

  isClient: boolean;
  isCreateFromCalender: boolean;
  isModify: boolean;
  isView: boolean;
  isDuplicate: boolean;
  currentSession: any;
  currentUser: User;
  meetingContexts = ['Conférence', 'Présentation', "Réunion d'équipe", 'Audioconférence', 'Autre'];
  timeList: Array<string> = [];
  users: User[] = [];
  sessionHost: User;
  participants: User[] = [];
  public sessionForm: FormGroup;
  isTitleInvalid = false;
  isDescriptionInvalid = false;
  isClientNameInvalid = false;
  isStartDateInvalid = false;
  isStartTimeInvalid = false;
  isEndDateInvalid = false;
  isEndTimeInvalid = false;
  activeTab = 'simple';
  showSimpleToolTip = false;
  showInviteToolTip = false;
  showDiffusionToolTip = false;
  selectedClient: User;
  participant: User;
  isCreateBtnDisabled = false;
  isCurrentUserVelotypiste = false;
  showClientEmail = false;
  placeholder = "Précisez ici l'ordre du jour, les noms des intervenants et autres informations complémentaires";
  formatter = (element: User) => element.prenom + " " + element.nom;
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        term === '' ? [] : this.users.filter((user: User) => user.displayName.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)
      )
    );

  searchAddParticipants = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        term === '' ? [] : this.users
          .filter((user: User) => this.participants.indexOf(user) == -1)
          .filter((user: User) => user.id != this.getSessionHostId())
          .filter((user: User) => user.displayName.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .slice(0, 10)
      )
    );

  constructor(
    private sessionService: SessionService,
    private api: ApiService,
    public modal: NgbActiveModal,
    private popupHandlerService: PopupHandlerService,
    private loaderService: LoaderService
  ) { }

  getSessionHostId() {
    return this.isClient ? this.currentUser.id : (this.selectedClient != null && this.selectedClient.id != null ? this.selectedClient.id : -1);
  }

  ngOnInit() {
    this.api.authenticatedUser.subscribe((user) => {
      if (user) {
        this.currentUser = user;
        if (user.type != "C") {
          if (user.fonctions.indexOf('velotypiste') != -1) {
            this.isCurrentUserVelotypiste = true;
            this.showClientEmail = this.isView && this.currentSession.id_velo == this.currentUser.id;
          }
        }
      }
    });

    this.loadClients();

    this.setTimelist();
    this.resetSessionForm();
    if (this.isModify || this.isView || this.isDuplicate) {
      this.loadSession();
      this.selectTab();
    }
    if (this.isCreateFromCalender) {
      this.loadClientName();
      this.loadDateTimes();
      this.changeEndTime();
    }
    this.loaderService.isLoading.subscribe(async data => {
      this.isCreateBtnDisabled = await data;
    });
  }

  loadClients() {
    this.api.getClients().subscribe(users => {
      this.users = users
      if (this.currentSession && !this.isClient) {
        this.selectedClient = this.loadSessionHost(users);
      }
    });
  }

  loadSessionHost(users: User[]) {
    return users.filter(user => user.id == this.currentSession.id_client)[0];
  }

  loadClientName() {
    let clientName = this.currentSession.client_display_name;
    if (clientName != null && clientName != "") {
      this.sessionForm.controls['clientName'].setValue(clientName);
    }
  }

  loadDateTimes() {
    this.loadDates();
    this.loadTimes();
  }

  loadDates() {
    let startDate: NgbDateStruct = { day: this.currentSession.debut.getDate(), month: this.currentSession.debut.getMonth() + 1, year: this.currentSession.debut.getFullYear() };
    let endDate: NgbDateStruct = { day: this.currentSession.fin.getDate(), month: this.currentSession.fin.getMonth() + 1, year: this.currentSession.fin.getFullYear() };
    this.sessionForm.controls['startDate'].setValue(startDate);
    this.sessionForm.controls['endDate'].setValue(endDate);
  }

  loadTimes() {
    this.sessionForm.controls['startTime'].setValue(formatDate(this.currentSession.debut, 'HH:mm', 'en'));
    this.sessionForm.controls['endTime'].setValue(formatDate(this.currentSession.fin, 'HH:mm', 'en'));
  }


  openHelpFile() {
    let target = environment.url + "files/prestation-optim.pdf"
    window.open(target, '_blank');
  }

  disableControls() {
    if (this.isView) {
      let startDate = this.sessionForm.controls['startDate'].value;
      this.sessionForm.setControl('startDate', new FormControl({ value: startDate, disabled: true }, [Validators.required]));
      let endDate = this.sessionForm.controls['startDate'].value;
      this.sessionForm.setControl('endDate', new FormControl({ value: endDate, disabled: true }, [Validators.required]));
    }
  }

  loadSession() {
    this.loadSessionStatus();

    //client email value is only viewed when viewing the event, not when editing/creating
    if (this.showClientEmail) {
      this.loadClientEmail();
    }

    this.loadClientName();
    this.sessionForm.controls['title'].setValue(this.currentSession.intitule);
    this.sessionForm.controls['theme'].setValue(this.currentSession.theme);
    this.sessionForm.controls['description'].setValue(this.currentSession.description);

    if (this.isModify || this.isView) {
      this.loadDateTimes();
    }
    else if (this.isDuplicate) {
      this.loadTimes();
    }

    this.participants = this.currentSession.participants;
    this.sessionForm.controls['participants'].setValue(this.currentSession.participants);

    this.sessionForm.addControl('url', new FormControl('url', null));
    this.sessionForm.controls['url'].setValue(this.currentSession.public_url);

    this.disableControls();
  }

  loadClientEmail() {
    let clientEmail = this.sessionHost.email;
    this.sessionForm.addControl('clientEmail', new FormControl('clientEmail', null));
    this.sessionForm.controls['clientEmail'].setValue(clientEmail);
  }

  loadSessionStatus() {
    //status value is only viewed when viewing the event, not when editing/creating
    if (this.isView) {
      let status = Object.keys(SessionStatus).find(key => SessionStatus[key] === this.currentSession.etat);
      this.sessionForm.addControl('status', new FormControl('status', null));
      this.sessionForm.controls['status'].setValue(status);
    }
  }

  resetSessionForm() {
    this.isDescriptionInvalid = false;
    this.isTitleInvalid = false;
    this.isClientNameInvalid = false;
    this.isStartDateInvalid = false;
    this.isStartTimeInvalid = false;
    this.isEndDateInvalid = false;
    this.isEndTimeInvalid = false;
    this.participants = [];
    this.sessionForm = new FormGroup({
      clientName: new FormControl(null),
      title: new FormControl(null, [Validators.required]),
      theme: new FormControl(this.meetingContexts[0]),
      description: new FormControl(null, [Validators.required]),
      startDate: new FormControl(null, [Validators.required]),
      startTime: new FormControl(this.timeList[50], [Validators.required]),
      endDate: new FormControl(null, [Validators.required]),
      endTime: new FormControl(this.timeList[52], [Validators.required]),
      participants: new FormControl(null),
      password: new FormControl(null)
    });
    this.updateClientNameValidation();
    this.activeTab = 'simple';
  }

  setClientNameRequired() {
    if (this.isClient) {
      return [];
    } else {
      return [Validators.required];
    }
  }

  updateClientNameValidation() {
    this.sessionForm.get('clientName').setValidators(this.setClientNameRequired());
    this.sessionForm.controls['clientName'].updateValueAndValidity();
  }


  setTimelist() {
    var quarterHours = ['00', '15', '30', '45'];
    for (var i = 0; i < 24; i++) {
      for (var j = 0; j < 4; j++) {
        var time = i + ':' + quarterHours[j];
        if (i < 10) {
          time = '0' + time;
        }
        this.timeList.push(time);
      }
    }
  }

  addParticipant() {
    if (this.isView)
      return;
    if (this.participant != null && this.users.indexOf(this.participant) != -1 && this.participants.indexOf(this.participant) == -1) {
      this.participants.push(this.participant);
    }
    delete this.participant;
  }

  deleteParticipant(index: number) {
    if (this.isView)
      return;
    if (this.participants.length > index && this.participants[index] !== null) {
      this.participants.splice(index, 1);
    }
  }

  changeEndTime() {
    if (
      !this.sessionForm.value.endTime ||
      this.timeList.indexOf(this.sessionForm.value.endTime) <=
      this.timeList.indexOf(this.sessionForm.value.startTime)
    ) {
      let index = this.timeList.indexOf(this.sessionForm.value.startTime);
      this.sessionForm.controls['endTime'].setValue(
        this.timeList[Math.min(index + 2, this.timeList.length - 1)]
      );
    }
  }

  //automatically set end date = start date in case startDate is modified
  changeEndDate() {
    this.sessionForm.controls['endDate'].setValue(this.sessionForm.value.startDate);
  }

  createSession() {
    let startDate = this.parseDateTime("start");
    let endDate = this.parseDateTime("end");
    if (this.isFormValid() && this.validateDates(startDate, endDate)) {
      let newSession = this.buildSession(startDate, endDate);

      this.sessionService
        .createSessionHandler(newSession, this.sessionForm.value.password)
        .subscribe((data) => {
          this.sessionService.getSessions();
          this.closePopup();
          this.popupHandlerService.openOneBtnPopup('Informations sur la demande', 'La demande a bien été enregistrée', 'fermer', false);
          this.resetSessionForm();
        },
          error => {
            if (error.reason != null) {
              this.popupHandlerService.openOneBtnPopup('Information', error.reason, 'Ok', true);
            }
            else if (error.status != null) {
              this.popupHandlerService.openOneBtnPopup('Information', error.status, 'Ok', true);
            }
          });
    }
  }

  private buildSession(startDate: Date, endDate: Date) {
    let newSession = new Session();
    let isCreateSession = !(this.isModify || this.isView || this.isDuplicate);
    newSession.debut = startDate;
    newSession.fin = endDate;
    newSession.intitule = this.sessionForm.value.title;
    newSession.theme = this.sessionForm.value.theme;
    newSession.description = this.sessionForm.value.description;
    newSession.publique = this.activeTab === 'diffusion';//not simple' neither 'invite' 

    newSession.id_client = isCreateSession ? this.getSessionHostId() : this.currentSession.id_client;
    newSession.etat = this.isModify ? this.currentSession.etat : SessionStatus.PENDING;

    if (this.isModify) {
      newSession.id = this.currentSession.id;
    }

    if (this.participants.length > 0) {
      newSession.participants = this.participants;
    }
    return newSession;
  }


  parseTime(fieldName: string) {
    let hours = this.sessionForm.value[fieldName].split(':')[0];
    let mins = this.sessionForm.value[fieldName].split(':')[1];
    return { hours, mins }
  }
  parseDateTime(fieldName: string) {
    let date = new Date();
    let formDate: any;
    switch (fieldName) {
      case 'start': {
        formDate = this.sessionForm.getRawValue().startDate;
        break;
      }
      case 'end': {
        formDate = this.sessionForm.getRawValue().endDate;
        break;
      }
    }
    if (!formDate) {
      return;
    }
    date.setFullYear(formDate.year, formDate.month - 1, formDate.day);
    let hours = this.parseTime(fieldName + "Time").hours;
    let mins = this.parseTime(fieldName + "Time").mins;
    date.setHours(hours, mins);
    return date;
  }

  validateDates(startDate: Date, endDate: Date) {
    //check
    if (startDate > endDate) {
      this.popupHandlerService.openOneBtnPopup('Information', 'La date de fin est inférieure à celle de début', 'Ok', true);
      return false;
    }
    return true;
  }

  //check that session form mandatory items are filled in
  isFormValid() {
    this.isClientNameInvalid = this.sessionForm.controls['clientName'].errors != null || this.isClientNameDataInvalid();
    this.isTitleInvalid = this.sessionForm.controls['title'].errors != null;
    this.isDescriptionInvalid = this.sessionForm.controls['description'].errors != null;
    this.isStartDateInvalid = this.sessionForm.controls['startDate'].errors != null;
    this.isStartTimeInvalid = this.sessionForm.controls['startTime'].errors != null;
    this.isEndDateInvalid = this.sessionForm.controls['endDate'].errors != null;
    this.isEndTimeInvalid = this.sessionForm.controls['endTime'].errors != null;
    return (
      !this.isClientNameInvalid &&
      !this.isTitleInvalid &&
      !this.isDescriptionInvalid &&
      !this.isStartDateInvalid &&
      !this.isStartTimeInvalid &&
      !this.isEndDateInvalid &&
      !this.isEndTimeInvalid
    );
  }

  isClientNameDataInvalid() {
    let isClientNameDisabled = this.isModify || this.isView || this.isCreateFromCalender;
    if (!isClientNameDisabled) {
      return this.getSessionHostId() == -1;
    }
    else return false;
  }

  selectTab() {
    if (this.currentSession.publique) {
      this.activeTab = 'diffusion';
    }
    else if (this.currentSession.participants.length > 0) {
      this.activeTab = 'invite';
    } else {
      this.activeTab = 'simple';
    }
  }

  closePopup() {
    delete this.selectedClient;
    this.modal.close("DONE");
  }
}
