import { Injectable } from '@angular/core';
import { NgbModalOptions, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CreateSessionPopupComponent } from '../views/popups/create-session-popup/create-session-popup.component';
import { Session } from '../models/session';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class CreateSessionPopupHandlerService {

  constructor(private modalService: NgbModal) { }

  modalRef: NgbModalRef;

  config: NgbModalOptions = {
    backdrop: 'static',
    size: 'lg'
  }

  private openCreatePopup(isClient: boolean, currentSession: Session, isView: boolean, isModify: boolean, isDuplicate: boolean, sessionHost?: User, refreshEvents?: any) {
    this.modalRef = this.modalService.open(CreateSessionPopupComponent, this.config);
    this.modalRef.componentInstance.isClient = isClient;
    this.modalRef.componentInstance.isModify = isModify;
    this.modalRef.componentInstance.isView = isView;
    this.modalRef.componentInstance.isDuplicate = isDuplicate;
    this.modalRef.componentInstance.currentSession = currentSession;
    if (!!sessionHost) {
      this.modalRef.componentInstance.sessionHost = sessionHost;
    }
    this.modalRef.result.then((result) => {
      if (result === 'DONE' && refreshEvents) {
        refreshEvents();
      }
    }, () => { })

  }

  createSessionFromCalendar(isClient: boolean, client: User, currentSession: Session, refreshEvents: any) {
    if (!this.modalService.hasOpenModals()) {
      this.modalRef = this.modalService.open(CreateSessionPopupComponent, this.config);
      this.modalRef.componentInstance.isClient = isClient;
      this.modalRef.componentInstance.isModify = false;
      this.modalRef.componentInstance.isView = false;
      this.modalRef.componentInstance.isCreateFromCalender = true;
      this.modalRef.componentInstance.selectedClient = client;
      this.modalRef.componentInstance.currentSession = currentSession;
      this.modalRef.result.then(() => { refreshEvents(); },
        () => { refreshEvents() })
    }
  }

  openCreateNewSessionWithRefresh(isClient: boolean, refreshEvents: any) { 
    this.openCreatePopup(isClient, null, false, false, false, null, refreshEvents);
  }
  openCreateNewSession(isClient: boolean) {
    this.openCreatePopup(isClient, null, false, false, false);
  }

  viewSession(isClient: boolean, currentSession: Session, sessionHost: User) {
    this.openCreatePopup(isClient, currentSession, true, false, false, sessionHost);
  }

  editSession(isClient: boolean, currentSession: Session, refreshEvents: any) { 
    this.openCreatePopup(isClient, currentSession, false, true, false, null, refreshEvents);
  }

  duplicateSession(isClient: boolean, currentSession: Session, refreshEvents: any) {
    this.openCreatePopup(isClient, currentSession, false, false, true, null, refreshEvents);
  }
}
