import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Session } from 'src/app/models/session';
import { SessionService } from 'src/app/services/session.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranscriptPopupComponent } from '../../popups/transcript-popup/transcript-popup.component';
import { PopupHandlerService } from 'src/app/services/popup-handler.service';
import { ConfirmWithTwoBtnsComponent } from '../../popups/confirm-with-two-btns/confirm-with-two-btns.component';


@Component({
  selector: 'app-session-archive-details',
  templateUrl: './session-archive-details.component.html',
  styleUrls: ['./session-archive-details.component.scss']
})
export class SessionArchiveDetailsComponent implements OnInit, OnChanges {

  @Input() currentSession: Session;
  @Input() currentURL: string;
  transcriptHTML;
  modalRef: NgbModalRef;
  constructor(private sessionService: SessionService,
    private modalService: NgbModal,
    private popupHandlerService: PopupHandlerService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentSession)
      this.getArchive();
  }

  getArchive() {
    this.sessionService.getArchive(this.currentSession.id).subscribe(data => {
      this.transcriptHTML = data;
    })
  }

  exportPDF() {
    if (this.currentSession != null) {
      //if transcript is empty to avoid failure of export backend error
      if (this.transcriptHTML == "") {
        this.sessionService.updateArchive(this.currentSession.id, "&#160;").subscribe();
      }
      this.sessionService.exportPDF(this.currentSession.id);
    }
  }

  modifyArchive() {
    this.modalRef = this.modalService.open(TranscriptPopupComponent,
      {
        centered: true,
        backdrop: 'static',
        size: 'lg'
      });
    this.modalRef.componentInstance.htmlContent = this.transcriptHTML;
    this.modalRef.componentInstance.title = "Edition de document";
    this.modalRef.componentInstance.archiveId = this.currentSession.id;
    this.modalRef.componentInstance.isView = false;
    this.modalRef.componentInstance.config = {
      editable: true,
      showToolbar: true,
      defaultFontSize: '3',
      spellcheck: true,
      height: '15rem',
      minHeight: '5rem',
      placeholder: 'Enter text here...',
      translate: 'no',
      toolbarPosition: 'top',
      defaultFontName: 'Times New Roman'
    };

    this.modalRef.result.then((result) => {
      this.transcriptHTML = result;
    }, () => { })
  }

  publishSession() {
    if (this.currentSession != null) {
      this.sessionService.publishSession(this.currentSession.id).subscribe(() => {
        this.sessionService.getSessions();
        this.transcriptHTML = '';
      }, error => {
        this.popupHandlerService.openOneBtnPopup('Information', error.reason, 'Ok', true);
      });
    }
  }

  deleteArchive() {
    if (this.currentSession != null) {
      let config: NgbModalOptions = {
        centered: true,
        backdrop: 'static',
        keyboard: false,
        size: 'sm'
      }
      this.modalRef = this.modalService.open(ConfirmWithTwoBtnsComponent, config);
      this.modalRef.componentInstance.title = 'Information';
      this.modalRef.componentInstance.message = 'delete-document';
      this.modalRef.componentInstance.okText = 'Accepter';
      this.modalRef.componentInstance.okAction = () => {
        this.sessionService.deleteArchive(this.currentSession.id).subscribe(
          () => {
            this.modalRef.close('close');
          },
          error => {
            this.popupHandlerService.openOneBtnPopup('Information', error.reason, 'Ok', true);
          }
        );
      }
      this.modalRef.componentInstance.cancelAction = () => this.modalRef.close('close');
    }
  }

}
