import { Injectable, QueryList } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { SortableHeaderDirective, SortEvent } from '../directives/sort-header.directive';
import { compare, Session } from '../models/session';
import { UserSessionStatus } from '../models/userSessionStatus';
@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  sortingChoices: SortEvent[] = [];
  userSortingChoices: BehaviorSubject<SortEvent[]>;

  calendarDate: Date = new Date();
  userDateChoice: BehaviorSubject<Date>;

  userSessionStatus: UserSessionStatus = new UserSessionStatus();
  currentUserSessionStatus: BehaviorSubject<UserSessionStatus>;

  constructor() {
    this.userSortingChoices = new BehaviorSubject(this.sortingChoices);
    this.userDateChoice = new BehaviorSubject(this.calendarDate);
    this.currentUserSessionStatus = new BehaviorSubject(this.userSessionStatus);
  }

  changeUserSessionStatus(newCurrentUserSessionStatus: UserSessionStatus) {
    this.currentUserSessionStatus.next(newCurrentUserSessionStatus);
  }

  changeUserDateChoice(newUserDateChoice: Date) {
    this.userDateChoice.next(newUserDateChoice);
  }

  changeUserSortingChoices(newUserChoices: SortEvent[]) {
    this.userSortingChoices.next(newUserChoices);
  }

  //choice numbers = (0: request management page, 1: finalization of requests, 2: documents management)
  sortUserchoice(choice: number, headers: QueryList<SortableHeaderDirective>, originalSessions: Session[]) {
    let selectedPageChoice = this.userSortingChoices.value[choice];
    if (selectedPageChoice != undefined) {
      let sessions: Session[];
      let direction = selectedPageChoice.direction;
      let column = selectedPageChoice.column;

      // resetting other headers
      if (headers != undefined) {
        headers.forEach(header => {
          if (header.sortable !== column) {
            header.direction = '';
          }
        });
      }

      // sorting session depend on column
      if (direction === '') {
        sessions = originalSessions;
      } else {
        sessions = [...originalSessions].sort((a, b) => {
          let res;
          if (column != "debut" && column != "date_demande") {
            res = compare(a[column].toLowerCase(), b[column].toLowerCase());
          }
          else {
            res = compare(a[column], b[column]);
          }
          return direction === 'asc' ? -res : res;
        });
      }
      return sessions;
    }
    else return originalSessions;
  }


}