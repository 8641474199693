import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PasswordValidation, RepeatPasswordValidator } from '../../../services/validator';
import { PopupHandlerService } from 'src/app/services/popup-handler.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  identicalPwd: boolean;
  isCurrentPasswordInvalid: boolean;
  isNewPasswordInvalid: boolean;
  isConfirmedNewPasswordInvalid: boolean;
  changePasswordForm: FormGroup;

  constructor(
    public modal: NgbActiveModal,
    private api: ApiService,
    private popupHandlerService: PopupHandlerService) { }

  ngOnInit() {
    this.resetForm();
  }

  private resetForm() {
    this.identicalPwd = true;
    this.isCurrentPasswordInvalid = false;
    this.isNewPasswordInvalid = false;
    this.isConfirmedNewPasswordInvalid = false;
    this.changePasswordForm = new FormGroup({
      currentPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', PasswordValidation),
      confirmedNewPassword: new FormControl(''),
    }, [RepeatPasswordValidator]);
  }


  changePassword() {
    if (this.isFormValid()) {
      this.api.changePassword(this.changePasswordForm.value.currentPassword, this.changePasswordForm.value.newPassword).subscribe(
        data => {
          if (data.code == 0) {
            this.popupHandlerService.openOneBtnPopup('Information', 'Changement de mot passe effectué', 'Ok', false);
            this.closePopup();
            this.resetForm();
          }
        },
        error => {
          this.popupHandlerService.openOneBtnPopup('Information', 'Le changement de mot passe a échoué', 'Ok', true);
        }
      );
    }
  }


  private isFormValid() {
    this.isCurrentPasswordInvalid = this.changePasswordForm.controls['currentPassword'].errors != null;
    if (this.changePasswordForm.errors != null && this.changePasswordForm.errors.passwordsNotEqual) {
      this.identicalPwd = false;
      this.isNewPasswordInvalid = true;
      this.isConfirmedNewPasswordInvalid = true;
      return false;
    }
    else {
      this.identicalPwd = true;
    }

    let newPasswordErrors = this.changePasswordForm.controls['newPassword'].errors;
    if (newPasswordErrors == null) {
      this.isNewPasswordInvalid = false;
    }
    else if (newPasswordErrors.minlength) {
      this.popupHandlerService.openOneBtnPopup('Information', 'Le mot de passe doit comporter entre 4 et 8 caractères chiffres et lettres', 'Ok', true);
      this.isNewPasswordInvalid = true;
    }
    else if (newPasswordErrors.pattern) {
      this.popupHandlerService.openOneBtnPopup('Information', 'Le mot de passe doit comporter au moins une minuscule, une majuscule, un chiffre et un caractère spécial.', 'Ok', true);
      this.isNewPasswordInvalid = true;
    }

    this.isConfirmedNewPasswordInvalid = this.changePasswordForm.controls['confirmedNewPassword'].errors != null;

    return (!this.isCurrentPasswordInvalid && !this.isNewPasswordInvalid && !this.isConfirmedNewPasswordInvalid && this.identicalPwd);
  }

  private closePopup() {
    this.modal.close('close');
  }
}