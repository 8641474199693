import { Component, OnInit, Output, EventEmitter, SimpleChanges, Input } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { compare, Session, SessionStatus } from 'src/app/models/session';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sessions-simple-list',
  templateUrl: './sessions-simple-list.component.html',
  styleUrls: ['./sessions-simple-list.component.scss']
})
export class SessionsSimpleListComponent implements OnInit {
  acceptedSessions: Session[] = [];
  search: string;
  browserLocale = 'fr';
  dateTimeformat = 'dd MMMM HH:mm';
  currentSessionId: number = -1;
  placeholder = "Rechercher par intitulé";
  @Output() simpleSessionItemSelected: EventEmitter<Session> = new EventEmitter();

  constructor(
    private sessionService: SessionService,
    private _translate: TranslateService
  ) { }

  ngOnInit() {
    this.browserLocale = this._translate.getBrowserLang() === 'en' ? 'en' : 'fr';
    this.dateTimeformat = this.browserLocale === 'en' ? 'dd MMMM h:mm a' : 'dd MMMM HH:mm';

    this.sessionService.sessions.subscribe(session => {
      this.prepareSessions(session);
    });
    this.sessionService.getSessions();
  }

  prepareSessions(session) {
    this.acceptedSessions = session.filter(
      s => this.sessionService.filterByScheduled.indexOf(s.etat) != -1
    );
    if (this.acceptedSessions.length != 0) {
      this.sortSessions();
      this.selectCurrentSession();
    }
    else {
      this.clearSelection();
    }
  }

  sortSessions() {
    this.acceptedSessions.sort((a, b) => {
      const res = compare(a['debut'], b['debut']);
      return res;
    });
  }

  selectCurrentSession() {
    if (this.currentSessionId != -1) {
      const currentSession: Session = this.filteredAcceptedSession.filter(session => session.id == this.currentSessionId)[0];
      this.onSelectSession(currentSession);
    }
    else if (this.filteredAcceptedSession[0]) {
      this.onSelectSession(this.filteredAcceptedSession[0]);
    }
  }

  get filteredAcceptedSession() {
    return !!this.search
      ? this.acceptedSessions.filter(
        (s: Session) =>
          s.client_display_name.toLowerCase().indexOf(this.search) !== -1 ||
          s.velotypiste.toLowerCase().indexOf(this.search) !== -1
      )
      : this.acceptedSessions;
  }

  isConfirmed(session: Session) {
    return session.etat == SessionStatus.CONFIRMED;
  }
  isValidated(session: Session) {
    return session.etat == SessionStatus.VALIDATED;
  }

  getBackgroundColor(session: Session) {
    if (this.isConfirmed(session)) {
      return '#00ff00';
    } else {
      return '#ff6600';
    }
  }

  onSelectSession(session: Session) {
    if (session == null) {
      return;
    }
    this.currentSessionId = session.id;
    this.simpleSessionItemSelected.emit(session);
  }

  clearSelection() {
    this.simpleSessionItemSelected.emit(null);
  }

}
