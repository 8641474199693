import { Transform } from 'class-transformer';

export class User {
    id: number;
    login: string;
    nom: string;
    prenom: string;

    adresse: string;

    @Transform(value => (value === 'Y'), { toClassOnly: true })
    archive: boolean;
    email: string;
    fonctions: string[];

    telephone_fixe: string;

    @Transform(value => (value === 'Y'), { toClassOnly: true })
    temporaire: boolean;

    type: 'C' | 'P';
    gestion_clients: string;
    gestion_demandes: string;
    gestion_personnel: string;
    fonction: number;
    telephone_velotypie: string;
    get displayName(){
        return `${this.nom} ${this.prenom}`;
    }
}