import { Injectable } from '@angular/core';
import { HTTPService } from './utils';
import { ReplaySubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { formatDate } from '@angular/common';
import { Session, SessionStatus } from '../models/session';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmWithTwoBtnsComponent } from '../views/popups/confirm-with-two-btns/confirm-with-two-btns.component';
import { PopupHandlerService } from './popup-handler.service';
import { SharedDataService } from './shared-data.service';
import { UserSessionStatus } from '../models/userSessionStatus';

@Injectable()
export class SessionService extends HTTPService {
  private sessions$ = new ReplaySubject<Session[]>(1);
  private currentSessions: Session[] = [];

  userSessionStatus: UserSessionStatus;

  filterByPending: SessionStatus[] = [SessionStatus.PENDING];
  filterByCanceled: SessionStatus[] = [SessionStatus.CANCELED];
  filterByRefused: SessionStatus[] = [SessionStatus.REFUSED];
  filterByConfirmed: SessionStatus[] = [SessionStatus.CONFIRMED];
  filterByValidated: SessionStatus[] = [SessionStatus.VALIDATED];
  filterByScheduled: SessionStatus[] = [
    SessionStatus.CONFIRMED,
    SessionStatus.VALIDATED
  ];
  constructor(
    protected http: HttpClient,
    private modalService: NgbModal,
    private popupHandlerService: PopupHandlerService,
    private sharedDataService: SharedDataService
  ) {
    super(http);

    this.sharedDataService.currentUserSessionStatus.subscribe(status => {
      this.userSessionStatus = status;
    })
  }

  get sessions() {
    return this.sessions$.asObservable();
  }

  clearSessionData() {
    this.sessions$.next([]);
  }

  getSessions() {
    this.http
      .get<Session[]>(`${environment.apiUrl}/session/getall`)
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          const data: any = plainToClass(Session, json.sessions);
          return data;
        })
      )
      .subscribe(sessions => {
        this.sessions$.next(sessions);
        this.currentSessions = sessions;
      });
  }

  changeSessionStatus(sessionId: number, status: string) {
    let params: HttpParams = new HttpParams().set(
      (status == 'close') ? 'id_session' : 'id_demande',
      sessionId.toString()
    );

    return this.http
      .get<any>(`${environment.apiUrl}/session/` + status, {
        params
      })
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json;
        })
      );
  }

  cancelSessionHandler(sessionId: number, refreshEvent?) {
    this.cancelSession(sessionId, false)
      .subscribe(res => {
        if (null != res.info) {
          this.cancelSessionPopup(sessionId, refreshEvent)
        }
        else {
          if (refreshEvent)
            refreshEvent();
          this.getSessions();
        }
      }, error => {
        this.popupHandlerService.openOneBtnPopup('Information', error.reason, 'Ok', true);
      });
  }

  cancelSessionPopup(sessionId: number, refreshEvent?) {
    let config: NgbModalOptions = {
      backdrop: 'static',
      size: 'sm',
      centered: true
    }
    const modalRef = this.modalService.open(ConfirmWithTwoBtnsComponent, config);
    modalRef.componentInstance.title = 'Information';
    modalRef.componentInstance.message = "Vous êtes sur le point d'annuler une session alors qu'elle débute dans moins de 24h. Si vous décidez de continuer, cette session sera annulée et facturée. Voulez-vous continuez ?";
    modalRef.componentInstance.okText = 'valider';
    modalRef.componentInstance.cancelAction = () => modalRef.close('close');
    modalRef.componentInstance.okAction = () => {
      this.cancelSession(sessionId, true).subscribe();
      if (refreshEvent)
        refreshEvent();
      this.getSessions();
      modalRef.close('close');
    }
  }

  cancelSession(sessionId: number, confirmStatus: boolean) {
    let params: HttpParams = new HttpParams();
    params = params.append('id_demande', sessionId.toString());

    if (confirmStatus)
      params = params.append('confirm', confirmStatus.toString());

    return this.http
      .get<any>(`${environment.apiUrl}/session/cancel`, {
        params
      })
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json;
        })
      );
  }

  buildSession(session: Session) {
    let body = new FormData();
    body.append('date_debut', formatDate(session.debut, 'yyyy-MM-dd', 'en'));
    body.append('heure_debut', formatDate(session.debut, 'HH:mm', 'en'));
    body.append('date_fin', formatDate(session.fin, 'yyyy-MM-dd', 'en'));
    body.append('heure_fin', formatDate(session.fin, 'HH:mm', 'en'));
    if (session.id != null) {
      body.append('id_demande', session.id.toString());
    }
    body.append('id_client', session.id_client.toString());
    body.append('intitule', session.intitule);
    body.append('theme', session.theme);
    body.append('etat', session.etat);
    if (session.description != null) {
      body.append('description', session.description);
    }
    if (session.capture != null) {
      body.append('capture', session.capture);
    }
    if (session.envoi_mail != null) {
      let envoi_mail = session.envoi_mail ? 'Y' : 'N';
      body.append('envoi_mail', envoi_mail);
    }
    if (session.envoi_courrier != null) {
      let envoi_courrier = session.envoi_courrier ? 'Y' : 'N';
      body.append('envoi_courrier', envoi_courrier);
    }
    if (session.publique != null) {
      let publique = session.publique ? 'Y' : 'N';
      body.append('publique', publique);
    }
    if (session.participants != null && session.participants.length > 0) {
      let participantsId: number[] = [];
      session.participants.forEach(element => {
        participantsId.push(element.id);
      });
      session.participants = participantsId;
      body.append('participants', session.participants.toString());
    }
    return body;
  }


  acceptSession(session: Session) {
    let body: FormData = this.buildSession(session);
    body.set('etat', SessionStatus.VALIDATED);
    return this.createSession(body);
  }

  createSessionHandler(session: Session, password: string) {
    let body: FormData = this.buildSession(session);
    if (password != null) {
      body.append('mot_de_passe', password);
    }
    return this.createSession(body);
  }

  createSession(body: FormData): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/session/create`, body)
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json;
        })
      );
  }



  assignSession(userId, sessionId): Observable<any> {
    let body = new FormData();
    body.append('id_demande', sessionId.toString());
    body.append('id_velo', userId.toString());

    return this.http
      .post<any>(`${environment.apiUrl}/session/assign`, body)
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json;
        })
      );
  }

  joinSession(sessionId: number, hash?: any) {
    let params: HttpParams = new HttpParams();
    params = params.append('id', sessionId.toString());

    //for public broadcasted sessions
    if (!!hash) {
      params = params.append('hash', hash.toString());
    }

    return this.http
      .get<Session[]>(`${environment.apiUrl}/buffer/getinfo`, {
        params
      })
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json;
        })
      );
  }

  getArchive(archiveId: number) {
    return this.http.get(`${environment.apiUrl}/session/get_archive?id_archive=${archiveId}`, { responseType: 'text' })
  }

  exportPDF(archiveId: number) {
    const pdfURL = `${environment.apiUrl}/session/get_archive?contentType=pdf&id_archive=`;
    window.open(pdfURL + `${archiveId}`);
    //  window.location.href = this.pdfURL + `${archiveId}`;
  }

  updateArchive(archiveId: number, content: string): Observable<any> {
    let body = new FormData();
    body.append('id_session', archiveId.toString());
    body.append('content', content);
    return this.http
      .post<any>(`${environment.apiUrl}/archive/update`, body)
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json;
        })
      );
  }

  deleteArchive(archiveId: number): Observable<any> {
    let httpParams = new HttpParams().set('id_session', archiveId.toString());
    let options = { params: httpParams };
    return this.http
      .delete<any>(`${environment.apiUrl}/archive/delete`, options)
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json;
        })
      );
  }
  terminateSession(sessionId: number): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/session/close?id_session=${sessionId}`)
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json;
        })
      );
  }

  publishSession(sessionId: number): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/session/publish?id_session=${sessionId}`)
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json;
        })
      );
  }

  setAccessArchive(sessionId: number, readable: boolean): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/archive/set_access?id_session=${sessionId}&readable=${readable}`)
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json;
        })
      );
  }


  isAlreadyInASession(currentUserID: number) {
    if (this.userSessionStatus && currentUserID == this.userSessionStatus.userId && this.userSessionStatus.isUserInSession) {
      this.popupHandlerService.openOneBtnPopup('Information', "already-in-session-error-message", 'Ok', true);
      return true;
    }
    return false;
  }

  private updateIsUserInSession(currentUserID, currentSessionID, isUserInSession) {
    this.userSessionStatus = {
      userId: currentUserID,
      sessionId: currentSessionID,
      isUserInSession: isUserInSession
    }
    this.sharedDataService.changeUserSessionStatus(this.userSessionStatus)
  }

  openTranscriptionWindow(currentUserID, currentSessionID) {
    let transcriptionWindow = window.open('./#/transcription?sessionId=' + currentSessionID, 'name', 'width=850,height=621');
    transcriptionWindow.addEventListener('load', () => {
      this.updateIsUserInSession(currentUserID, currentSessionID, true);
    });

    transcriptionWindow.addEventListener('beforeunload', () => {
      this.updateIsUserInSession(currentUserID, currentSessionID, false);
    });
  }

  isCurrentUserAllowedToJoinSession(currentSession: Session, currentUserID: number) {
    if (currentSession == null) { return false; }
    let isTheVelotypist = currentUserID == currentSession.id_velo;
    let isTheClient = currentUserID == currentSession.id_client;
    let isParticipantClient = currentSession.participants.find(p => p.id == currentUserID);
    return isTheVelotypist || isTheClient || isParticipantClient;
  }

}
