import { Component, OnInit, Output, EventEmitter, Input, ViewChildren, QueryList } from '@angular/core';
import { compare, Session, SessionStatus } from 'src/app/models/session';
import { SessionService } from 'src/app/services/session.service';
import { SortEvent, SortableHeaderDirective } from 'src/app/directives/sort-header.directive';
import { ApiService } from 'src/app/services/api.service';
import { User } from 'src/app/models/user';
import { TranslateService } from '@ngx-translate/core';
import { SharedDataService } from 'src/app/services/shared-data.service';

@Component({
  selector: 'app-session-archive-list',
  templateUrl: './session-archive-list.component.html',
  styleUrls: ['./session-archive-list.component.scss']
})
export class SessionArchiveListComponent implements OnInit {

  @Input() currentURL: string;

  @Output() onSessionSelected: EventEmitter<Session> = new EventEmitter();

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  user: User;
  isAdminDocumentManagement = false;


  constructor(private sessionService: SessionService,
    protected api: ApiService,
    private _translate: TranslateService,
    private sharedDataservice: SharedDataService) { }

  browserLocale = 'fr';
  filteredSessions: Session[] = [];
  originalSessions: Session[] = [];
  currentSession;
  isClient = false;
  search: string;
  userSortingChoices: SortEvent[];
  placeholder = "Rechercher par intitulé";
  ngOnInit() {
    this.browserLocale = this._translate.getBrowserLang() === 'en' ? 'en' : 'fr';

    this.api.authenticatedUser.subscribe(user => {
      if (user) {
        this.user = user;
        this.isClient = this.user.type == 'C'
      }
    })
    const sessionStatus = this.currentURL == 'document' ? SessionStatus.ARCHIVED : SessionStatus.FINISHED;
    this.isAdminDocumentManagement = (this.currentURL == 'document' && this.user.type == 'P');
    this.sessionService.sessions.subscribe(sessions => {
      this.filteredSessions = sessions.filter(
        session => session.etat == sessionStatus
      );
      this.filteredSessions = [...this.filteredSessions].sort((a, b) => {
        return compare(a, b);
      });
      this.originalSessions = this.filteredSessions;

      this.sharedDataservice.userSortingChoices.subscribe(choices => {
        this.userSortingChoices = choices;
        if (this.currentURL == 'terminate') {
          this.filteredSessions = this.sharedDataservice.sortUserchoice(1, this.headers, this.originalSessions);
        }
        else if (this.currentURL == 'document') {
          this.filteredSessions = this.sharedDataservice.sortUserchoice(2, this.headers, this.originalSessions);
        }
      })
    });
    this.sessionService.getSessions();
  }

  onSelectSession(session: Session) {
    this.currentSession = session;
    this.onSessionSelected.emit(session);
  }

  onSort({ column, direction }: SortEvent) {
    if (this.currentURL == 'terminate') {
      this.userSortingChoices[1] = { column, direction };
    }
    else if (this.currentURL == 'document') {
      this.userSortingChoices[2] = { column, direction };
    }
    this.sharedDataservice.changeUserSortingChoices(this.userSortingChoices)
  }

  get filteredTerminatedSession() {
    return !!this.search
      ? this.filteredSessions.filter(
        (s: Session) =>
          s.client_display_name.toLowerCase().indexOf(this.search) !== -1 ||
          s.velotypiste.toLowerCase().indexOf(this.search) !== -1 ||
          s.intitule.toLowerCase().indexOf(this.search) !== -1
      )
      : this.filteredSessions;
  }

  accessClientChange(values: any) {
    this.sessionService.setAccessArchive(values.currentTarget.id, values.currentTarget.checked).subscribe(() => {
    }, error => {
      // this.popupHandlerService.openOneBtnPopup('Information', error.reason, 'Ok', true);
    });
  }
}
