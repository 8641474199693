import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Session, SessionStatus } from 'src/app/models/session';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models/user';
import { SessionService } from 'src/app/services/session.service';
import { ChangePasswordComponent } from '../credentials/change-password/change-password.component';
import { CreateSessionPopupHandlerService } from 'src/app/services/create-session-popup-handler.service';
import { SettingsPopupComponent } from '../popups/settings-popup/settings-popup.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  sessions: Session[] = [];
  isClient: boolean = false;
  isVelotypiste: boolean = false;
  isAdmin: boolean = false;
  isCorrecteur: boolean = false;
  user: User;
  modalRef: NgbModalRef;
  settingsData;
  @Output() msg: EventEmitter<String> = new EventEmitter();

  constructor(
    private sessionService: SessionService,
    private api: ApiService,
    private modalService: NgbModal,
    private createSessionPopupHandlerService: CreateSessionPopupHandlerService
  ) { }

  ngOnInit() {
    this.sessionService.sessions.subscribe(sessions => {
      this.sessions = sessions;
    });
    this.sessionService.getSessions();
    this.api.authenticatedUser.subscribe(user => {
      if (user) {
        this.user = user;
        this.detectRole(user);
      }
    });

    this.loadSettings();
  }

  private detectRole(user: any) {
    if (user.type == 'C') {
      this.isClient = true;
    } else if (user.type == 'P') {
      if (user.fonctions.indexOf('velotypiste') != -1) {
        this.isVelotypiste = true;
      }
      if (user.fonctions.indexOf('admin') != -1) {
        this.isAdmin = true;
      }
      if (user.fonctions.indexOf('correcteur') != -1) {
        this.isCorrecteur = true;
      }
    }
  }

  get nbOfPendingSessions() {
    return this.sessions.filter(s => s.etat === SessionStatus.PENDING).length;
  }

  get ngOfSessionsToAssign() {
    return this.sessions.filter(s => s.etat === SessionStatus.VALIDATED).length;
  }

  get ngOfFinishedSessions() {
    return this.sessions.filter(s => s.etat === SessionStatus.FINISHED).length;
  }

  changePassword() {
    this.modalService.open(ChangePasswordComponent,
      {
        centered: true,
        backdrop: 'static',
      });
  }

  openCreateNewSession() {
    this.createSessionPopupHandlerService.openCreateNewSession(this.isClient);
  }

  openSettings() {
    let config: NgbModalOptions = {
      backdrop: 'static',
      size: 'lg',
      centered: true
    }
    this.modalRef = this.modalService.open(SettingsPopupComponent, config);
    this.modalRef.componentInstance.title = 'settings';
    this.modalRef.componentInstance.okText = 'valider';
    this.modalRef.componentInstance.announcementMessage = this.settingsData.announcementMessage;
    this.modalRef.componentInstance.okAction = () => {
      let updatedSettings = {
        "announcementMessage": this.modalRef.componentInstance.announcementMessage
      }
      this.updateSettings(updatedSettings);
      this.modalRef.close('close');
    };
  }

  updateSettings(updatedSettings) {
    this.settingsData = updatedSettings;
    this.api.setSettings(updatedSettings).subscribe();
    this.msg.emit(this.settingsData.announcementMessage)
  }

  loadSettings() {
    this.api.getSettings().subscribe(
      data => {
        this.settingsData = data;
        this.msg.emit(this.settingsData.announcementMessage);
      },
      error => {
      }
    );
  }
}