import { Component, OnInit, Input } from '@angular/core';
import { Session, SessionStatus } from 'src/app/models/session';
import { ApiService } from 'src/app/services/api.service';
import { User } from 'src/app/models/user';
import { SessionService } from 'src/app/services/session.service';
import { TranslateService } from '@ngx-translate/core';
import { TerminateSessionService } from 'src/app/services/terminate-session-service';
import { PopupHandlerService } from 'src/app/services/popup-handler.service';
import { CreateSessionPopupHandlerService } from 'src/app/services/create-session-popup-handler.service';
import { UserSessionStatus } from 'src/app/models/userSessionStatus';

@Component({
  selector: 'app-session-details',
  templateUrl: './session-details.component.html',
  styleUrls: ['./session-details.component.sass']
})
export class SessionDetailsComponent implements OnInit {
  @Input() isAssignment: boolean;
  @Input() currentSession: Session;
  currentUser: User;
  isCurrentUserClient = false;
  browserLocale = 'fr';
  userSessionStatus: UserSessionStatus;
  constructor(
    private sessionService: SessionService,
    private terminateSessionService: TerminateSessionService,
    private api: ApiService,
    private _translate: TranslateService,
    private createSessionPopupHandlerService: CreateSessionPopupHandlerService,
    private popupHandlerService: PopupHandlerService
  ) { }

  ngOnInit() {
    this.browserLocale = this._translate.getBrowserLang() === 'en' ? 'en' : 'fr';
    this.api.authenticatedUser.subscribe((user) => {
      if (user) {
        this.currentUser = user
        this.isCurrentUserClient = user.type === 'C'
      }
    });
  }


  terminateSession() {
    const sessionId = this.currentSession.id;
    const clearSessionFunc = this.clearCurrentSession.bind(this);
    this.terminateSessionService.terminateSessionPopup(sessionId, clearSessionFunc);
  }

  changeStatus(status: string) {
    switch (status) {
      case 'cancel':
        this.cancelSession();
        break;
      case 'close':
        this.terminateSession();
        break;
      default:
        this.sessionService
          .changeSessionStatus(this.currentSession.id, status)
          .subscribe(res => {
            this.sessionService.getSessions();
            this.clearCurrentSession();
          }, error => {
            this.popupHandlerService.openOneBtnPopup('Information', error.reason, 'Ok', true);
          });
    }
  }

  cancelSession() {
    const sessionId = this.currentSession.id;
    const clearSessionFunc = this.clearCurrentSession.bind(this);

    this.sessionService.cancelSessionHandler(sessionId, clearSessionFunc);
  }

  acceptSession() {
    // this.currentSession.etat = SessionStatus.VALIDATED;
    this.sessionService.acceptSession(this.currentSession).subscribe(res => {
      this.sessionService.getSessions();
      this.clearCurrentSession();
    }, error => {
      this.popupHandlerService.openOneBtnPopup('Information', error.reason, 'Ok', true);
    });
  }

  modifySession() {
    if (this.currentSession != null) {
      this.createSessionPopupHandlerService.editSession(this.isCurrentUserClient, this.currentSession, this.clearCurrentSession.bind(this));
    }
  }

  clearCurrentSession() {
    this.currentSession = null;
  }

   
  joinSession() {   
    if (this.sessionService.isCurrentUserAllowedToJoinSession(this.currentSession, this.currentUser.id) && !this.sessionService.isAlreadyInASession(this.currentUser.id)) {
      this.sessionService.joinSession(this.currentSession.id).subscribe(() => {
        this.sessionService.openTranscriptionWindow(this.currentUser.id, this.currentSession.id);
      });
    } 
  }

  getBackgroundColor(session: Session) {
    const bgColors = {};
    bgColors[SessionStatus.PENDING] = '#9595dd';
    bgColors[SessionStatus.CONFIRMED] = '#c5f6ad';
    bgColors[SessionStatus.CANCELED] = '#f69999';
    bgColors[SessionStatus.REFUSED] = '#fd5c31';
    bgColors[SessionStatus.ARCHIVED] = '#d3d3d3';
    bgColors[SessionStatus.VALIDATED] = '#64b8d1';
    bgColors[SessionStatus.FINISHED] = '#f1e747';
    return bgColors[session.etat] || 'black';
  }

  showButtonAccept(): boolean {
    return (
      this.sessionService.filterByPending.indexOf(this.currentSession.etat) !==
      -1 && !this.isCurrentUserClient
    );
  }
  showButtonJoin(): boolean {
    return (
      this.sessionService.filterByConfirmed.indexOf(
        this.currentSession.etat
      ) === 0 && this.isCurrentUserClient
    );
  }
  showButtonModifiy(): boolean {
    return (this.sessionService.filterByScheduled.concat(this.sessionService.filterByPending).indexOf(this.currentSession.etat) !== -1 && this.isCurrentUserClient) ||
      (this.sessionService.filterByScheduled.indexOf(this.currentSession.etat) !== -1 && !this.isCurrentUserClient)
  }
  showButtonCanceled(): boolean {
    return (this.sessionService.filterByScheduled.concat(this.sessionService.filterByPending).indexOf(this.currentSession.etat) !== -1 && this.isCurrentUserClient) ||
      (this.sessionService.filterByScheduled.indexOf(this.currentSession.etat) !== -1 && !this.isCurrentUserClient);
  }
  showButtonRefuse(): boolean {
    return this.sessionService.filterByScheduled.concat(this.sessionService.filterByPending).indexOf(this.currentSession.etat) !== -1 && !this.isCurrentUserClient
  }
  showButtonRestore(): boolean {
    return this.sessionService.filterByRefused.indexOf(this.currentSession.etat) !== -1 && !this.isCurrentUserClient
  }
  showButtonDelete(): boolean {
    return this.sessionService.filterByRefused.concat(this.sessionService.filterByCanceled).indexOf(this.currentSession.etat) !== -1 && !this.isCurrentUserClient;
  }
  showButtonTerminate(): boolean {
    return this.isAssignment;
  }
}
