import { Injectable, Component } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class I18n {
  language = 'fr';
  constructor(private translate: TranslateService) {
    this.language = this.translate.getDefaultLang(); 
  }
}

@Injectable()
export class DatepickerlocalService extends NgbDatepickerI18n {
  constructor(private _i18n: I18n, private translateService: TranslateService) {
    super();
  }
  getWeekdayShortName(weekday: number): string {
    let d = 'weekday.' + (weekday - 1);
    return this.translateService.instant(d);
  }
  getMonthShortName(month: number): string {
    let m = 'month.' + (month - 1);
    return this.translateService.instant(m);
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
