import { Injectable } from '@angular/core';
import { HTTPService } from './utils';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { plainToClass } from 'class-transformer';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from '../models/user';
import { Md5 } from 'ts-md5/dist/md5';
import { SessionService } from './session.service';

@Injectable()
export class ApiService extends HTTPService {
  private users$ = new ReplaySubject<User[]>(1);
  private currentUser$ = new ReplaySubject<User>(1);

  constructor(
    protected http: HttpClient,
    private sessionService: SessionService
  ) {
    super(http);
  }

  get authenticatedUser() {
    return this.currentUser$.asObservable();
  }

  get users() {
    return this.users$.asObservable();
  }

  clearData() {
    this.sessionService.clearSessionData();
    this.users$.next([]);
    this.currentUser$.next(null);
  }

  authenticate(login: string, password: string): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiUrl}/connexion`,
        this.toHttpParams({ login, passwd: Md5.hashStr(password) }).toString(),
        {
          headers: new HttpHeaders().set(
            'Content-Type',
            'application/x-www-form-urlencoded'
          )
        }
      )
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          const authenticatedUser: any = plainToClass(User, json.user);
          this.currentUser$.next(authenticatedUser);
          return authenticatedUser;
        })
      );
  }

  getMe(): Observable<any> {
    return this.http.get<User[]>(`${environment.apiUrl}/me`).pipe(
      map((json: any) => {
        this.checkAPIError(json);
        const authenticatedUser: any = plainToClass(User, json.user);
        this.currentUser$.next(authenticatedUser);
        return authenticatedUser;
      })
    );
  }

  getSettings(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/settings`)
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json.settings;
        })
      );
  }

  setSettings(settings: any): Observable<any> {
    let formBody = new FormData();
    Object.keys(settings).map(key => formBody.append(key, settings[key]));
    return this.http.post<any>(`${environment.apiUrl}/settings`, formBody).pipe(
      map((json: any) => {
        return json;
      })
    );
  }

  getPersonnels(): Observable<any> {
    return this.http
      .get<User[]>(`${environment.apiUrl}/user/get_personnels`)
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          const data: any = plainToClass(User, json.personnels);
          this.users$.next(data);
          return data;
        })
      );
  }

  getClients(): Observable<any> {
    return this.http.get<User[]>(`${environment.apiUrl}/user/get_clients`).pipe(
      map((json: any) => {
        this.checkAPIError(json);
        const data: any = plainToClass(User, json.clients);
        this.users$.next(data);
        return data;
      })
    );
  }

  getPlanning(date: string): Observable<any> {
    // let curDate = new Date(date);
    // formatDate(curDate, 'yyyy/MM/dd', 'en');
    return this.http
      .get<any>(`${environment.apiUrl}/user/get_planning?date=${date}`)
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          // const data: any = plainToClass(User, json.planning);
          // this.users$.next(data);
          return json.planning;
        })
      );
  }

  getCalendar(userId: number, startDate: string, endDate: string): Observable<any> {
    return this.http
      .get<any>(
        `${
        environment.apiUrl
        }/user/get_calendar?id_personne=${userId}&datestart=${startDate}&dateend=${endDate}`
      )
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json.agenda;
        })
      );
  }

  logout() {
    return this.http.get<any>(`${environment.apiUrl}/logout`).pipe(
      tap(() => {
        this.clearData();
      })
    );
  }

  changePassword(currPwd: string, newPwd: string): Observable<any> {
    let formdata = new FormData();
    formdata.append('oldpass', Md5.hashStr(currPwd).toString());
    formdata.append('newpass', Md5.hashStr(newPwd).toString());
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http
      .post<any>(`${environment.apiUrl}/account/change_password`, formdata, {
        headers: headers
      })
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json;
        })
      );
  }

  generateNewPassword(email: string): Observable<any> {
    let formdata = new FormData();
    formdata.append('email', email);
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http
      .post<any>(`${environment.apiUrl}/account/lost_password`, formdata, {
        headers: headers
      })
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json;
        })
      );
  }

  updateAccount(user: User): Observable<any> {
    let body = new FormData();
    body.append('adresse', user.adresse);
    body.append('email', user.email);
    body.append('nom', user.nom);
    body.append('prenom', user.prenom);
    body.append('telephone_fixe', user.telephone_fixe);
    body.append('telephone_velotypie', user.telephone_velotypie);
    return this.http
      .post<any>(`${environment.apiUrl}/account/update`, body)
      .pipe(
        map((json: any) => {
          return json;
        })
      );
  }



  buildUserForm(user: User) {
    let body = new FormData();
    body.append('adresse', user.adresse);
    body.append('email', user.email);
    body.append('nom', user.nom);
    body.append('prenom', user.prenom);
    body.append('telephone_fixe', user.telephone_fixe);
    body.append('type', user.type);
    body.append('login', user.login);
    body.append('gestion_clients', user.gestion_clients);
    body.append('gestion_demandes', user.gestion_demandes);
    body.append('gestion_personnel', user.gestion_personnel);
    body.append('fonction', '' + user.fonction);
    return body;
  }
  createNewUser(user: User): Observable<any> {
    let formBody = this.buildUserForm(user);
    return this.http.post<any>(`${environment.apiUrl}/user/create`, formBody).pipe(
      map((json: any) => {
        return json;
      })
    );
  }

  updateUser(userId, user: User): Observable<any> {
    let formBody = this.buildUserForm(user);
    formBody.append('id_personne', userId);
    return this.http.post<any>(`${environment.apiUrl}/user/update`, formBody).pipe(
      map((json: any) => {
        return json;
      })
    );
  }

  /*
   /user/delete
   POST
 */
  deleteUser(userId: number): Observable<any> {
    let body = new FormData();
    body.append('id_personne', userId.toString());
    return this.http
      .post<any>(`${environment.apiUrl}/user/delete`, body)
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json;
        })
      );
  }


  checkCondition(res: string) {
    let params: HttpParams = new HttpParams().set(
      'a',
      res
    );
    return this.http
      .get<any[]>(`${environment.apiUrl}/user/check_condition`, {
        params
      })
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json;
        })
      );
  }


  getCheckConditionMsg() {
    return this.http
      .get<string>(`assets/CGU.txt`, { responseType: 'text' as 'json' });
  }
}
