import { Component, OnInit } from '@angular/core';
import { TranscriptionService } from 'src/app/services/transcription.service';
import { Params, ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { ApiService } from 'src/app/services/api.service';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-transcription-info',
  templateUrl: './transcription-info.component.html',
  styleUrls: ['./transcription-info.component.css']
})
export class TranscriptionInfoComponent implements OnInit {

  constructor(
    private sessionService: SessionService,
    private api: ApiService,
    private route: ActivatedRoute,
    private socketService: SocketService,
    public transcriptionService: TranscriptionService
  ) { }


  title = 'Informations sur la session';
  isSessionLoaded = false;
  isBroadcastParticipant = false;

  ngOnInit() {
    this.joinBroadcastSession();
    if (!this.isBroadcastParticipant) {
      this.joinSession();
    }
  }

  joinBroadcastSession() {
    this.route.queryParams.subscribe((params: Params) => {
      let currentSessionId = params['sessionId'];
      let currentParticipant = params['username'];
      let hash = params['hash'];
      if (!!currentParticipant) {
        this.isBroadcastParticipant = true;
        this.sessionService.joinSession(currentSessionId, hash).subscribe((s) => {
          this.setupSession(s, currentParticipant);
        })
      }
    })
  }

  joinSession() {
    this.api.authenticatedUser.subscribe((u) => {
      if (u) {
        let currentUser = u;
        this.route.queryParams.subscribe((params: Params) => {
          let currentSessionId = params['sessionId'];
          this.sessionService.joinSession(currentSessionId).subscribe((s) => {
            this.setupSession(s, currentUser);
          })
        })
      }
    });
  }

  setupSession(s, currentUser) {
    s.currentUser = currentUser;
    this.transcriptionService.setTranscription(s);
    this.socketService.connect();
    setTimeout(() => {
      this.isSessionLoaded = true;
    }, 1000)
  }

}
