import { Component, OnInit } from '@angular/core';
import { Session } from '../../models/session'; 

@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.css']
})
export class AssignmentsComponent implements OnInit {

  currentSession: Session;

  constructor() { }

  ngOnInit() {
  }
  setCurrentSession(session) {
    this.currentSession = session;
  }
}
