import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupHandlerService } from 'src/app/services/popup-handler.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-transcript-popup',
  templateUrl: './transcript-popup.component.html',
  styleUrls: ['./transcript-popup.component.scss']
})
export class TranscriptPopupComponent implements OnInit {
  constructor(
    public modal: NgbActiveModal,
    private sessionService: SessionService,
    private popupHandlerService: PopupHandlerService) {
  }

  isView: boolean;
  htmlContent = '';
  archiveId: number;
  title = "";
  config: AngularEditorConfig;

  ngOnInit() {
  }

  closePopup() {
    this.modal.close(this.htmlContent);
  }

  saveDocument() {
    this.sessionService.updateArchive(this.archiveId, this.htmlContent).subscribe(
      res => {
        this.closePopup();
      },
      error => {
        this.popupHandlerService.openOneBtnPopup('Information', error.reason, 'Ok', true);
      }
    );
  }
}
