import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HTTPService } from './utils';
import { map } from 'rxjs/operators';
import { Observer, Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class DictionnaryService extends HTTPService {
  private dictionary$ = new ReplaySubject<any[]>(1);

  constructor(
    protected http: HttpClient
  ) {
    super(http);

  }

  get dictionary() {
    return this.dictionary$.asObservable();
  }

  /*
    /dictionary/getall
    GET
  */

  getAll() {
    this.http
      .get<any>(`${environment.apiUrl}/dictionary/getall` + status)
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json.dictionaries;
        })
      ).subscribe((dictionary) => {
        this.dictionary$.next(dictionary);
      });
  }

  /*
    /dictionary/update
    POST
  */
  update(key: string, value: string, id_dico: string) {
    // key: aaa
    // value: aaa
    // id_dico: -

    let body = new FormData();
    body.append('key', key);
    body.append('value', value);
    body.append('id_dico', id_dico);

    return this.http
      .post<any>(`${environment.apiUrl}/dictionary/update`, body)
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json;
        })
      );
  }


  deleteGlobal(key: string) {
    const ID_DICO_GLOBAL = 0;
    let body = new FormData();

    body.append('key', key);
    body.append('id_dico', ID_DICO_GLOBAL.toString());

    return this.delete(body);
  }

  deletePersonal(key) {
    let body = new FormData();
    body.append('key', key);
    return this.delete(body);
  }

  /*
    /dictionary/delete
    POST
  */
  private delete(body: FormData) {
    // key:

    return this.http
      .post<any>(`${environment.apiUrl}/dictionary/delete`, body)
      .pipe(
        map((json: any) => {
          this.checkAPIError(json);
          return json;
        })
      );
  }




  formatDictionary(dico) {
    let allWords: any = {};
    dico.map(
      (singleDico: any) => {
        singleDico.raccourcis.map(record => {
          allWords[record.cle] = record.valeur;
        })
      }
    )
    return allWords;
  }
}
