import 'reflect-metadata';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from './services/api.service';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AngularSplitModule } from 'angular-split';
import { SessionsListComponent } from './views/sessions/sessions-list/sessions-list.component';
import { MenuComponent } from './views/menu/menu.component';
import { UsersListComponent } from './views/users-list/users-list.component';
import { LoginComponent } from './views/credentials/login/login.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { MainComponent } from './views/main/main.component';
import { CustomHttpInterceptor } from './interceptors/custome-http.interceptor';
import { ContextMenuModule } from 'ngx-contextmenu';
import { UserDetailsComponent } from './views/user-details/user-details.component';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { SortableHeaderDirective } from './directives/sort-header.directive';
import { SessionDetailsComponent } from './views/sessions/session-details/session-details.component';
import { SessionsComponent } from './views/sessions/sessions.component';
import { CreateSessionPopupComponent } from './views/popups/create-session-popup/create-session-popup.component';
import { SessionService } from './services/session.service';
import { ChangePasswordComponent } from './views/credentials/change-password/change-password.component';
import { AssignmentsComponent } from './views/assignments/assignments.component';
import { SessionsSimpleListComponent } from './views/assignments/sessions-simple-list/sessions-simple-list.component';
import { AssignmentTimelineComponent } from './views/assignments/assignment-timeline/assignment-timeline.component';
import { ConfirmWithTwoBtnsComponent } from './views/popups/confirm-with-two-btns/confirm-with-two-btns.component';
import { ConfirmWithOneBtnComponent } from './views/popups/confirm-with-one-btn/confirm-with-one-btn.component';
import { TerminateSessionService } from './services/terminate-session-service';
import { TranscriptPopupComponent } from './views/popups/transcript-popup/transcript-popup.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DictionaryComponent } from './views/dictionary/dictionary.component';
import { AddUserComponent } from './views/popups/add-user/add-user.component';
import { DictionnaryService } from './services/dictionnary.service';
import { CalendarComponent } from './views/calendar/calendar.component';
import { TranscriptionComponent } from './views/transcription/transcription.component';
import { MessageService } from './services/message.service';
import { TranscriptionTextareaComponent } from './views/transcription/transcription-textarea/transcription-textarea.component';
import { SocketService } from './services/socket.service';
import { TranscriptionService } from './services/transcription.service';
import { SessionArchiveComponent } from './views/session-archive/session-archive.component';
import { SessionArchiveListComponent } from './views/session-archive/session-archive-list/session-archive-list.component';
import { SessionArchiveDetailsComponent } from './views/session-archive/session-archive-details/session-archive-details.component';
import { TranscriptionInfoComponent } from './views/transcription/transcription-info/transcription-info.component';
import { TranscriptionExtractedInfoComponent } from './views/transcription/transcription-extracted-info/transcription-extracted-info.component';
import { SettingsPopupComponent } from './views/popups/settings-popup/settings-popup.component';
import { ParticipateBroadcastComponent } from './views/participate-broadcast/participate-broadcast.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    MenuComponent,
    SessionsListComponent,
    UsersListComponent,
    LoginComponent,
    UserDetailsComponent,
    SortableHeaderDirective,
    SessionsComponent,
    SessionDetailsComponent,
    CreateSessionPopupComponent,
    ChangePasswordComponent,
    AssignmentsComponent,
    SessionsSimpleListComponent,
    AssignmentTimelineComponent,
    ConfirmWithTwoBtnsComponent,
    ConfirmWithOneBtnComponent,
    TranscriptPopupComponent,
    DictionaryComponent,
    AddUserComponent,
    CalendarComponent,
    TranscriptionComponent,
    TranscriptionTextareaComponent,
    SessionArchiveComponent,
    SessionArchiveListComponent,
    SessionArchiveDetailsComponent,
    TranscriptionInfoComponent,
    TranscriptionExtractedInfoComponent,
    SettingsPopupComponent,
    ParticipateBroadcastComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    AngularSplitModule.forRoot(),
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ContextMenuModule.forRoot({
      useBootstrap4: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AngularEditorModule
  ],
  providers: [
    TranslateService,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    ApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    SessionService,
    TerminateSessionService,
    DictionnaryService,
    MessageService,
    SocketService,
    TranscriptionService
    // {provide: NgbDateParserFormatter, useClass: DateformaterService}
  ],
  bootstrap: [AppComponent],
  entryComponents: [TranscriptionInfoComponent, AddUserComponent, CreateSessionPopupComponent, ChangePasswordComponent, ConfirmWithOneBtnComponent, ConfirmWithTwoBtnsComponent, TranscriptPopupComponent, SettingsPopupComponent]
})
export class AppModule {
  constructor(public translate: TranslateService) {
    let lang = translate.getBrowserLang() === 'en' ? 'en': 'fr';
    // console.log('the current browser lang is ', lang);
    this.translate.setDefaultLang(lang);
  }
}
