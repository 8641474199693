import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { User } from 'src/app/models/user';
import { ApiService } from 'src/app/services/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { noWhitespaceValidator } from 'src/app/services/validator';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  //get this from parent component
  isModify: boolean;
  currentUser: User;
  userType;


  invalidLogin: string;
  invalidNom: string;
  invalidPrenom: string;
  invalidEmail: string;
  invalidTelephoneFixe: string;
  invalidAdresse: string;
  inValidForm: boolean = false;
  public userForm: FormGroup;
  newUser: User;
  staffType = [
    'Transcripteur',
    'Admin',
    'Transcripteur/Admin',
    'Correcteur',
    'Transcripteur/Correcteur',
    'Correcteur/Admin',
    'Transcripteur/Correcteur/Admin'
  ];
  adminOptions = [
    'gérer les clients',
    'gèrer le personnel',
    'gérer les sessions'
  ];
  isAdmin: boolean = false;


  constructor(
    public modal: NgbActiveModal,
    private api: ApiService) { }

  ngOnInit() {
    this.userForm = new FormGroup({
      login: new FormControl(null, [Validators.required, noWhitespaceValidator]),
      nom: new FormControl(null, [Validators.required, noWhitespaceValidator]),
      prenom: new FormControl(null, [Validators.required, noWhitespaceValidator]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      telephone_fixe: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9+]*$/), Validators.pattern(/[^. ]*/), noWhitespaceValidator]),
      adresse: new FormControl(null, [Validators.required, noWhitespaceValidator]),
      autorisations: new FormControl(this.staffType[0]),
      gestion_clients: new FormControl(null),
      gestion_personnel: new FormControl(null),
      gestion_demandes: new FormControl(null)
    });
    if (this.isModify) {
      this.loadUser();
      this.userForm.controls["login"].disable();
    }
  }

  loadUser() {
    this.userForm.controls["login"].setValue(this.currentUser.login);
    this.userForm.controls["nom"].setValue(this.currentUser.nom);
    this.userForm.controls["prenom"].setValue(this.currentUser.prenom);
    this.userForm.controls["email"].setValue(this.currentUser.email);
    this.userForm.controls["telephone_fixe"].setValue(this.currentUser.telephone_fixe);
    this.userForm.controls["adresse"].setValue(this.currentUser.adresse);
    this.userForm.controls["gestion_clients"].setValue(this.currentUser.gestion_clients === 'Y' ? true : false);
    this.userForm.controls["gestion_personnel"].setValue(this.currentUser.gestion_personnel === 'Y' ? true : false);
    this.userForm.controls["gestion_demandes"].setValue(this.currentUser.gestion_demandes === 'Y' ? true : false);

    //
    if (this.userType == 'P') {
      let transcripteur = (this.currentUser.fonctions.indexOf("velotypiste") != -1) ? 1 : 0;
      let admin = (this.currentUser.fonctions.indexOf("admin") != -1) ? 2 : 0;
      let correcteur = (this.currentUser.fonctions.indexOf("correcteur") != -1) ? 4 : 0;
      this.userForm.controls["autorisations"].setValue(this.staffType[transcripteur + admin + correcteur - 1]);
      if (admin) {
        this.onFieldChange();
      }
    }
  }

  resetUserForm() {
    delete this.inValidForm;
    delete this.invalidLogin;
    delete this.invalidNom;
    delete this.invalidPrenom;
    delete this.invalidEmail;
    delete this.invalidTelephoneFixe;
    delete this.invalidAdresse;
  }

  save() {
    this.newUser = this.userForm.getRawValue();
    if (
      this.userForm.value.autorisations &&
      this.userForm.value.autorisations.toLowerCase().includes('admin')
    ) {
      this.newUser.fonction = this.staffType.indexOf(this.userForm.value.autorisations) + 1;
    }
    this.resetUserForm();
    this.validateNewUserInfo(this.newUser);

    if (!this.inValidForm) {
      this.newUser.type = this.userType;
      if (this.isModify) {
        this.api.updateUser(this.currentUser.id, this.newUser).subscribe(res => {
          this.validateApi(res);
        })
      }
      else
        this.api.createNewUser(this.newUser).subscribe(res => {
          this.validateApi(res);
        });
    }
  }
  validateApi(res) {
    if (res.reason) {
      this.inValidForm = true;
      if (res.reason.email) {
        this.invalidEmail = res.reason.email;
      }
      if (res.reason.login) {
        this.invalidLogin = res.reason.login;
      }
      return;
    }
    if (!this.inValidForm) {
      let updatedUsers;
      if (this.newUser.type == 'P') {
        this.api.getPersonnels().subscribe(users => (updatedUsers = users));
      } else if (this.newUser.type == 'C') {
        this.api.getClients().subscribe(users => (updatedUsers = users));
      }
      this.modal.close(
        {
          users: updatedUsers,
          isAdded: true,
          firstName: this.userForm.value.prenom,
          lastName: this.userForm.value.nom,
        }
      );
      this.userForm.reset();
    }
  }

  validateNewUserInfo(user: User) {
    if (!user.login || this.userForm.controls['login'].errors != null) {
      this.invalidLogin = 'Identifiant manquant';
      this.inValidForm = true;
    }
    if (!user.nom || this.userForm.controls['nom'].errors != null) {
      this.invalidNom = 'Nom manquant';
      this.inValidForm = true;
    }
    if (!user.prenom || this.userForm.controls['prenom'].errors != null) {
      this.invalidPrenom = 'Prenom manquant';
      this.inValidForm = true;
    }
    if (this.userForm.controls['email'].errors != null && this.userForm.controls['email'].errors.email) {
      this.invalidEmail = 'Adresse mail incorrecte';
      this.inValidForm = true;
    } else if (!user.email || this.userForm.value.email === '') {
      this.invalidEmail = 'Adresse email manquant';
      this.inValidForm = true;
    }
    if (this.userForm.controls['telephone_fixe'].errors != null &&
      (this.userForm.controls['telephone_fixe'].errors.pattern || this.userForm.controls['telephone_fixe'].errors.whitespace)) {
      this.invalidTelephoneFixe = 'Numéro de téléphone incorrect';
      this.inValidForm = true;
    } else if (!user.telephone_fixe || this.userForm.value.telephone_fixe === '') {
      this.invalidTelephoneFixe = 'Numéro de téléphone manquant';
      this.inValidForm = true;
    }
    if (!user.adresse || this.userForm.controls['adresse'].errors != null) {
      this.invalidAdresse = 'Adresse manquante';
      this.inValidForm = true;
    }
  }


  onFieldChange() {
    if (this.userForm.value.autorisations.toLowerCase().includes('admin')) {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
      this.userForm.controls['gestion_clients'].setValue(false);
      this.userForm.controls['gestion_personnel'].setValue(false);
      this.userForm.controls['gestion_demandes'].setValue(false);
    }
  }

}
